export const service_Blown = {
  title: "Foundation Crack Repair",
  url: "/Images/s-bg-2.png",
  info: "Cracks in concrete are inevitable.  Leaking cracks can turn your basement into a nightmare, flooding your house during a heavy rain episode and can ruined a finished space.  Ensuring complete repair on any cracks will keep your basement dry and prevent costly repairs and water damage to finished spaces.",
}
export const madeFrom = [
  {
    id: 1,
    url: "/Images/made-6.png",
    title: "High Pressure Crack Injection",
  },
  {
    id: 2,
    url: "/Images/made-7.png",
    title: "Low Pressure Crack Injection",
  },
  {
    id: 3,
    url: "/Images/made-8.png",
    title: "Seal-N-Peel Removable Surface Seal",
  },
]

export const servies_info = {
  title: "The Mystery of the Leaking Basement",
  url: "/Images/sf-1.png",
  info: "Aging concrete often develops cracks due to groundwater pressure around your home's foundation, which may cause leaks into your basement. Below-grade installation of plumbing or electrical pipes can also cause water infiltration. If you are lucky enough to discover a leaking crack in your concrete in an unfinished basement,  foundation crack repair is usually an easy and obvious fix.  Often, the signs of a leaking crack can start as small drip stains on your concrete, usually a while or orange color, or can be as serious as puddles or inches of water inside your basement. If you have a finished basement and suddenly discover puddles on your floor or wet baseboards or walls, it can feel like a complete mystery.  Our experts are armed with tools to further investigate to uncover potential leaking cracks in a minimally invasive manner. ",
}
export const second_info = {
  title: "Sealing a Leaking Crack Once and For All",
  url: "/Images/sf-2.png",
  info: "While there are many products marketed as crack sealants or waterproofing paints manufactured for use on basement walls, they are not made to stand the test of time and often the crack will reappear as the water in the ground continues to put pressure on your foundation.  You may even be a new homeowner who has found a leak from a prior owner’s DIY crack repair.  If you plan to finish your basement at any point, or have a finished basement experiencing leaks, you need a permanent and proven crack sealing that will provide long tern peace of mind.Our experts use a multi-step solution that penetrates the entire crack from top to bottom through to the outside of the foundation.  First, the surface is pr FIX FIX FIX FIX",
}

export const values = [
  {
    id: 1,
    URL: "/Images/icon6.png",
    title: "Versatile Solutions",
    info: "Cosmetically appealing solutions available to maintain aesthetic of specialty concrete",
  },
  {
    id: 2,
    URL: "/Images/history-line.png",
    title: "Long-Lasting Benefits",
    info: "Civil-Engineer backed technology for a permanent solution.",
  },
  {
    id: 3,
    URL: "/Images/windy-line.png",
    title: "Increase Indoor Air Quality",
    info: "Reduce soil gases entering in through cracks in your concrete foundation.",
  },
  {
    id: 4,
    URL: "/Images/close-circle-line.png",
    title: "Stop Further Damage",
    info: "Restore integrity to your foundation and prevent further water damage.",
  },
  {
    id: 5,
    URL: "/Images/icon1.png",
    title: "Cost Effective",
    info: "Arrest structural degradation before costly repairs need to be made. ",
  },
  {
    id: 6,
    URL: "/Images/icon3.png",
    title: "Minimally Invasive",
    info: "Sealing cracks from the inside bypasses the need to disturb landscaping..",
  },
]

export const title = "Benefits of Concrete Crack Repair"
