import React from "react"

function ContactComponent() {
  return (
    <div className="flex justify-center items-center text-center w-full flex-col mt-[131px] gap-y-[30px] bg-[#1869A9] py-[60px] px-[30px] ">
      <div className="flex justify-center items-center text-white font-[300px] text-[24px]">
        Contact Us
      </div>
      <div className="flex justify-center items-center text-white mobile:text-[30px]  text-[40px] text-[700]  ">
        How Can We Help?
      </div>
      <div className="flex justify-center items-center text-[22px] text-white w-full max-w-[700px] font-[300]">
        Send us a note and we will reach out to schedule an appointment as soon
        as possible.
      </div>
    </div>
  )
}

export default ContactComponent
