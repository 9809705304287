import { Button } from "antd";
import React from "react";

function ButtonContact() {
  return (
    <div className="flex justify-center items-center mb-[30px] w-full">
      <div className="flex justify-center items-center w-full max-w-[300px] ">
        <Button
          className={`felx items-center justify-center text-[20px] bg-[#AAE127] border-[#AAE127] hover:!text-black  hover:border-1-solid hover:border-[#AAE127]  rounded-[26px] h-[50px] w-full`}
        >
          Contact us
        </Button>
      </div>
    </div>
  );
}
export default ButtonContact;
