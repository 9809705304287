import React, { useEffect } from "react";
import MainLayout from "../component/shared/layout/MainLayout";
import LadyComponent from "../component/shared/LadyComponent/LadyComponent";
import CertifiedComponent from "../component/shared/Certified/CertifiedComponent";
import FreeQuoteToday from "../component/shared/FreeQuote/FreeQuoteToday";
import AboutWork from "../component/AboutWork/AboutWork";
import HouseHomeComponent from "../component/shared/HomeHouseComponent.jsx/HomeHouseComponent";
import PageTopComponent from "../component/shared/PageTopComponet/PageTopComponent";
import { aboutHousepage, aboutpage } from "../Services/About";
import { useLocation } from "react-router-dom";

function About() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <MainLayout>
        <PageTopComponent componentdata={aboutpage} />
        <HouseHomeComponent houseData={aboutHousepage} />
        <AboutWork />
        <LadyComponent />
        <CertifiedComponent />
        <FreeQuoteToday />
      </MainLayout>
    </div>
  );
}

export default About;
