import React from "react"
import FindLocationInput from "../findLocationInput/FindLocationInput"

function ServicesPageTopComponent({ pageData, pageName }) {
  return (
    <div
      className={
        pageName
          ? `flex justify-center items-center  service-bg-1   mt-[40px] w-full h-[600px]`
          : `flex justify-center items-center  services-bg-2   mt-[40px] w-full h-[600px] `
      }
    >
      <div className="flex justify-center flex-col mt-[70px]  items-center w-full center px-[30px] max-w-[800px] absolute bottom-50 tablet:mt-[50px]">
        <div className="flex justify-center  items-center text-center mobile:text-[30px]  text-[40px] text-white font-[700] ">
          {pageData?.title}
        </div>
        <div className="flex justify-center items-center text-center my-[30px] text-[20px] font-[400px] text-white tablet:text-[16px]   ">
          {pageData?.info}
        </div>
        <div className="flex justify-center items-center w-full max-w-[500px]">
          <FindLocationInput />
        </div>
      </div>
    </div>
  )
}

export default ServicesPageTopComponent
