export const service_spray = {
  title: "Spray Foam Insulation",
  url: "/Images/s-bg-1.png",
  info: "Spray foam is a highly effective form of insulation that is comprised of a two-part product that can be applied to walls, attics, and more.  It expands to fill air gaps and provides a long-lasting, highly effective insulation.",
}
export const madeFromSpray = [
  {
    id: 1,
    url: "/Images/made-1.png",
    title: "Open-Cell Foam",
  },
  {
    id: 2,
    url: "/Images/made-2.png",
    title: "Closed-Cell Foam ",
  },
]

export const servies_info = {
  title: "Benefits of Spray Foam Insulation",
  url: "/Images/sp-1.png",
  info: "Spray foam is a uniquely effective form of insulation due to the fact that it can actually provide structural integrity to a building.  Spray foam has been commonly used in commercial applications due to its effectiveness in providing both incredible R-Values as well as providing a barrier from moisture and air sealing any gaps in a structure.  Inch for inch, spray foam provides the highest R-value compared to any other type of insulation.Spray foam is an excellent way to combine air sealing and insulation in one step as it expands rapidly to fill gaps, even in the tiniest of crevices.  It is especially useful in homes that do not have a true “attic”, such as A-frame homes.  While Blown-in insulation and batt insulation are incredibly resistant to settling over time, because spray foam hardens and cures it can never settle or reduce in efficiency",
}
export const second_info = {
  title: "Types of Spray Foam Insulation",
  url: "/Images/sp-2.png",
  info: "Crawlspace encapsulation is a lasting and highly effective solution that offers numerous benefits. The process includes air sealing, insulating, and securing a durable vapor barrier along the walls of your crawlspace. Any vents are sealed, and rim joists are insulated and sealed with spray foam.  This comprehensive approach keeps water out, creating a dry and clean environment that prevents mold growth and preserves the integrity of your floor joists.Unlike traditional vapor barriers that may shift, accumulate deposits, and fail to regulate humidity adequately, total encapsulation provides long-lasting results, improving indoor air quality and helping regulate  floor temperatures. If you're interested in experiencing the benefits of full crawlspace encapsulation, don't hesitate to contact us. Our experts are here to walk you through the process and enhance both the comfort and integrity of your home. ",
}

export const values = [
  {
    id: 1,
    URL: "/Images/icon4.png",
    title: "Fits Any Space",
    info: "Expands to fill tiny crevices that other insulation cannot",
  },
  {
    id: 2,
    URL: "/Images/history-line.png",
    title: "Long-Lasting Insulation",
    info: "Maintain effectiveness for decades.",
  },
  {
    id: 3,
    URL: "/Images/windy-line.png",
    title: "Air and Vapor Seal",
    info: "Air seals to improve indoor air quality and keeps moisture out.",
  },
  {
    id: 4,
    URL: "/Images/speaker.png",
    title: "Sound Reduction",
    info: " Can provide excellent sound-dampening.",
  },
  {
    id: 5,
    URL: "/Images/home-2-line.png",
    title: "Adds Structural Integrity",
    info: "Can provide improved strength to buildings.",
  },
  {
    id: 6,
    URL: "/Images/icon1.png",
    title: "Save on Energy Bills",
    info: "Air sealing and high R-Value can dramatically reduce energy bills.",
  },
]

export const titleSpray = "Benefits of Crawl Space Encapsulation"
