import React, { useEffect, useState } from "react";
import TopBar from "./TopBar";
import { Button, Drawer, Dropdown, Image } from "antd";

import {
  ArrowRightOutlined,
  DownOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import ModalForm from "../../Modal/Modal";

const flexCenter = "flex items-center";

const navbar = [
  {
    id: 1,
    title: "Reviews ",
    href: "/reviews",
  },
  {
    id: 2,
    title: "About",
    href: "/about",
  },
  {
    id: 3,
    title: "Free Resources ",
    href: "/free-resoures",
  },
  {
    id: 4,
    title: "Contact Us",
    href: "/contact",
  },
  {
    id: 5,
    title: "Financing",
    href: "/financing",
  },
];

const itemOne = [
  {
    label: "Services",
    href: "/services ",
    key: "1",
  },
  {
    label: "Batt Insulation",
    href: "/services-fiberglass-batt-insulation",
    key: "3",
  },
  {
    label: "Blown In Insulation",
    href: "/services-blown-insulation",
    key: "4",
  },
  {
    label: "Spray Foam Insulation",
    href: "/services-sparyFoam-insulation",
    key: "5",
  },
];
const itemTwo = [
  {
    label: " Solar Attic Fans",
    href: "/services-attic-fan ",
    key: "8",
  },
  {
    label: "Insulation Removal",
    href: "/services-attic-insulation-removal",
    key: "9",
  },
  {
    label: "Foundation Crack Repair",
    href: "/services-foundation-craks-repair",
    key: "7",
  },
  {
    label: "Crawl Space Encapsulation",
    href: "/services-crawlspace-encapsulation",
    key: "6",
  },
];

const itemThree = [
  {
    label: "Weatherizing",
    href: "/services-attic-fan-wetherizing",
    key: "10",
  },
  {
    label: "Energy Audits",
    href: "/services-energy-audits",
    key: "11",
  },
  {
    label: "Mold Removal",
    href: "/services-blown-removal-insulation",
    key: "12",
  },
  {
    label: "Blower Door Testing",
    href: "/servies-blower-door-testing",
    key: "13",
  },
];
const items = [
  {
    label: <a href="/services ">Services</a>,
    key: "1",
  },
  {
    label: <a href="/services-fiberglass-batt-insulation">Batt Insulation</a>,
    key: "3",
  },
  {
    label: <a href="/services-blown-insulation">Blown In Insulation</a>,
    key: "4",
  },
  {
    label: <a href="/services-sparyFoam-insulation">Spray Foam Insulation</a>,
    key: "5",
  },
  {
    label: (
      <a href="/services-crawlspace-encapsulation">Crawl Space Encapsulation</a>
    ),
    key: "6",
  },
  {
    label: (
      <a href="/services-foundation-craks-repair">Foundation Crack Repair</a>
    ),
    key: "7",
  },
  {
    label: <a href="/services-attic-fan ">Solar Attic Fans</a>,
    key: "8",
  },
  {
    label: <a href="/services-attic-insulation-removal">Insulation Removal</a>,
    key: "9",
  },
  {
    label: <a href="/services-attic-fan-wetherizing">Weatherizing</a>,
    key: "10",
  },
  {
    label: <a href="/services-energy-audits">Energy Audits</a>,
    key: "11",
  },
  {
    label: <a href="/services-blown-removal-insulation">Mold Removal</a>,
    key: "12",
  },
  {
    label: <a href="/servies-blower-door-testing">Blower Door Testing</a>,
    key: "13",
  },
];

function Header() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const router = (path) => {
    navigate(`${path}`);
    setOpen(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 right-0  opacity-100 z-50 w-full ">
      <TopBar />
      <div
        className={
          scrolled
            ? `${flexCenter} justify-center tablet_LG:justify-between w-full h-[91px] px-[40px] gap-x-[30px] mobile:px-[20px] bg-[#dbd9d9] `
            : `${flexCenter} justify-center  tablet_LG:justify-between w-full h-[91px] px-[40px]  gap-x-[30px] mobile:px-[20px] bg-[#ffffff8d] `
        }
      >
        <div
          onClick={() => router("/")}
          className={`${flexCenter} justify-end  tablet_LG:justify-start  w-full max-w-[300px]  h-full max-h-[76px] img `}
        >
          <Image
            src="/Images/logo.png"
            alt="logo"
            preview={false}
            className="cursor-pointer object-cover"
          />
        </div>
        <div
          className={`${flexCenter} tablet_LG:hidden justify-center w-full  `}
        >
          <div
            className={`${flexCenter} justify-start w-full max-w-[100px]  cursor-pointer`}
          >
            <Dropdown
              overlay={
                <div className="flex justify-between items-center py-[20px] gap-x-[20px] h-full  bg-white  w-full min-w-[900px]">
                  <div className="flex justify-center items-center w-full flex-col min-w-[300px] py-[30px]  h-full ">
                    <div className="flex justify-center items-center text-[26px] font-[700]">
                      Services
                    </div>
                    <div
                      className="flex justify-center cursor-pointer items-center text-[16px] font-[500] text-[#AAE127]"
                      onClick={() => navigate("/services")}
                    >
                      {" "}
                      View All &nbsp;&nbsp; <ArrowRightOutlined />
                    </div>
                  </div>
                  <div className="flex justify-start items-start w-full flex-col">
                    {itemOne.map((item) => {
                      return (
                        <div
                          onClick={() => router(item.href)}
                          className="flex justify-start items-start py-[10px] font-[500] cursor-pointer "
                          key={item.id}
                        >
                          {item.label}
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex justify-start items-start w-full flex-col  ">
                    {itemTwo.map((item) => {
                      return (
                        <div
                          onClick={() => router(item.href)}
                          className="flex justify-start items-start py-[10px] font-[500] cursor-pointer  "
                          key={item.id}
                        >
                          {item.label}
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex justify-start items-start   w-full flex-col">
                    {itemThree.map((item) => {
                      return (
                        <div
                          onClick={() => router(item.href)}
                          className="flex justify-start items-start py-[10px] font-[500] cursor-pointer  "
                          key={item.id}
                        >
                          {item.label}
                        </div>
                      );
                    })}
                  </div>
                </div>
              }
              // menu={{
              //   items,
              // }}
            >
              <a href={() => false} onClick={(e) => e.preventDefault()}>
                <div className={`${flexCenter} justify-center gap-x-[10px]`}>
                  Services
                  <DownOutlined className="mt-[5px]" />
                </div>
              </a>
            </Dropdown>
          </div>
          <div className={`${flexCenter} justify-center w-full  `}>
            {navbar?.map((nav) => {
              return (
                <div
                  key={nav.id}
                  className={
                    location.pathname === nav.href
                      ? `${flexCenter}   justify-center   text-[16px] font-[400] w-full   leading-normal cursor-pointer text-blue-800 `
                      : `${flexCenter}   justify-center   text-[16px] font-[400] w-full   leading-normal cursor-pointer `
                  }
                  onClick={() => router(nav.href)}
                >
                  {nav?.title}
                </div>
              );
            })}{" "}
          </div>

          <div className={`${flexCenter}  justify-end  h-[40px] pl-[20px] `}>
            <Button
              onClick={openModal}
              className={`${flexCenter} justify-center text-[16px] bg-[#AAE127] border-[#AAE127] hover:!text-black hover:!border-[#AAE127] rounded-[26px] `}
            >
              Request A Quote
            </Button>
          </div>
        </div>

        <div
          className={`${flexCenter} justify-end hidden tablet_LG:block items-center`}
        >
          <MenuOutlined
            className={`${flexCenter} text-[33px]`}
            onClick={() => showDrawer()}
          />
        </div>
      </div>
      <div
        className={`${flexCenter} justify-center w-full drawer_head  form_modal `}
      >
        <Drawer
          placement="right"
          onClose={onClose}
          open={open}
          className={`${flexCenter} justify-center  drawer_head w-full`}
        >
          <div className="h-[40px] bg-[#1869A9] "></div>
          <div
            className={`${flexCenter} justify-between  w-full h-[91px] px-[15px]`}
          >
            <div
              className={`${flexCenter} justify-center w-[170px] h-full max-h-[76px] img `}
            >
              <Image
                src="/Images/logo.png"
                alt="logo"
                preview={false}
                className="object-cover"
              />
            </div>
            <div className={`${flexCenter} justify-end items-center`}>
              <MenuOutlined
                className={`${flexCenter} text-[33px]`}
                onClick={() => onClose()}
              />
            </div>
          </div>

          <div
            className={`${flexCenter} flex-col justify-center h-[calc(100vh-131px)] w-full   `}
          >
            <div
              className={`${flexCenter} justify-center w-full  cursor-pointer`}
            >
              <Dropdown
                menu={{
                  items,
                }}
              >
                <a href={() => false} onClick={(e) => e.preventDefault()}>
                  <div className={`${flexCenter} justify-center gap-x-[10px]`}>
                    Services
                    <DownOutlined className="mt-[5px]" />
                  </div>
                </a>
              </Dropdown>
            </div>
            <div className={`${flexCenter} flex-col justify-center  `}>
              {navbar?.map((nav) => {
                return (
                  <div
                    onClick={() => router(nav.href)}
                    key={nav.id}
                    className={
                      location.pathname === nav.href
                        ? `${flexCenter} justify-center flex-col  text-[16px] font-[400] mt-[30px]  leading-normal cursor-pointer  text-[#1869A9]`
                        : `${flexCenter} justify-center flex-col  text-[16px] font-[400] mt-[30px]  leading-normal cursor-pointer  `
                    }
                  >
                    {nav?.title}
                  </div>
                );
              })}{" "}
            </div>

            <div
              className={`${flexCenter}  justify-center  h-[40px] mt-[30px] `}
            >
              <Button
                onClick={openModal}
                className={`${flexCenter} justify-center text-[16px] bg-[#AAE127] border-[#AAE127] hover:!text-black  hover:!border-[#AAE127] rounded-[26px] `}
              >
                Request A Quote{" "}
              </Button>
            </div>
          </div>
        </Drawer>
        <ModalForm openModal={isModalOpen} setmodal={setIsModalOpen} />
      </div>
    </div>
  );
}

export default Header;
