export const service_fiber = {
  title: " Batt Insulation",
  url: "/Images/s-bg-1.png",
  info: "Batt Insulation is an affordable and easy-to-install insulating material, generally made out of fiberglass.  While it’s versatile and affordable, it also has its limitations.  We generally recommend fiberglass batt insulation for the walls of your home.",
}

export const servies_info = {
  title: "Benefits and Types of Batt Insulation",
  url: "/Images/s-info-1.png",
  info: "Batt insulation is a highly recognizable and widely used form of insulation found in numerous homes. Its primary application lies within the walls, where it plays a crucial role in maintaining indoor comfort. What makes batt insulation such a popular choice is its versatility, cost-effectiveness, and availability in various sizes, materials, and R-values. The installation process is usually quick and straightforward, ensuring timely completion.If you desire a more comfortable, energy-efficient, and quieter living environment, batt insulation might be the ideal solution for your home. Contact us to find out more and see if batt insulation is the solution to your home's insulation needs.",
}

export const values = [
  {
    id: 1,
    URL: "/Images/chat-check-fill.png",
    title: "Quick Installation",
    info: "Installation is quicker than most types of insulation.",
  },
  {
    id: 2,
    URL: "/Images/history-line.png",
    title: "Long-Lasting Insulation",
    info: "Can maintain effectiveness for decades.",
  },
  {
    id: 3,
    URL: "/Images/icon1.png",
    title: "Highly Cost Effecient",
    info: "Costs less due to quick and easy installation.",
  },
  {
    id: 4,
    URL: "/Images/icon6.png",
    title: "Versatile",
    info: " Installation is quicker than most types of insulation.",
  },
  {
    id: 5,
    URL: "/Images/fire-line.png",
    title: "Naturally FIre-Resistant",
    info: "Makes your home more resistant to fire.",
  },
  {
    id: 6,
    URL: "/Images/speaker.png",
    title: "Sound Reduction",
    info: "Provides some sound-reduction between rooms.",
  },
]

export const title = "Benefits of Batt Insulation"
