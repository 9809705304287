import React from "react";
import ReactSlider from "../ReactSlider/ReactSlider";
import { services } from "../../Services/Services";

function OurServices() {
  return (
    <div className="flex flex-col justify-center items-center mt-[40px]">
      <div className="flex justify-center items-center flex-col">
        <div className="flex justify-center items-center text-[40px] font-[700]  text-center">
          Our Services
        </div>
        <div className="flex justify-center items-center text-center px-[30px] text-[20px] font-[400]">
          Explore how we can help your home to achieve its full potential.
        </div>
      </div>
      <div>
        <ReactSlider servicecomponent={services} />
      </div>
    </div>
  );
}

export default OurServices;
