export const services = [
  {
    id: 1,
    title: "Blown-In Insulation",
    url: "/Images/s-1.png",
  },
  {
    id: 2,
    title: "Batt Insulation",
    url: "/Images/s-2.png",
  },
  {
    id: 3,
    title: "Spray Foam Insulation",
    url: "/Images/s-3.png",
  },
  {
    id: 4,
    title: "Crawl Space Encapsulation",
    url: "/Images/s-4.png",
  },
  {
    id: 5,
    title: "Basement Crack Repair",
    url: "/Images/s-5.png",
  },
  {
    id: 6,
    title: "Solar Attic Fan",
    url: "/Images/s-6.png",
  },
  {
    id: 7,
    title: "Insulation Clean Up and Removal",
    url: "/Images/s-7.png",
  },
  {
    id: 8,
    title: "Weatherizing Doors and Windows",
    url: "/Images/s-8.png",
  },
  {
    id: 9,
    title: "Energy Assessments",
    url: "/Images/s-9.png",
  },
];

export const servicepage = {
  page: "Services",
  title: "Learn More About The Services We offer",
  info: "",
  imageUrl: "/Images/s-img.png",
  background: "services",
  backgroundColor: "#1869A9",
  bgColorOpacity: "#3db0e15a",
  buttonColor: "#AAE127",
  button: true,
};
