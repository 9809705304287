import { ArrowRightOutlined } from "@ant-design/icons";
import { Image } from "antd";
import React from "react";

function ServiceCard({ item }) {
  return (
    <div
      key={item?.url}
      className="flex mobile:!flex-row justify-center items-center h-full w-full relative"
    >
      <Image
        src={item?.url}
        alt="image"
        preview={false}
        width="100%"
        height="100%"
        className="object-cover"
      />

      <div className="flex justify-center items-center flex-col py-[20px] bg-[#186aa9ce] absolute bottom-0 w-[94%] rounded-[20px] ">
        <div className="flex justify-center items-center w-full px-[10px] h-[70px] text-center text-white text-[20px] my-[10px] font-[700]">
          {item?.title}
        </div>
        <div className="flex justify-center items-center capitalize gap-x-[30px] mb-[20px] text-[20px] text-[#AAE127]">
          learn More
          <span className="flex justify-center items-center  text-[25px]">
            <ArrowRightOutlined />
          </span>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
