import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Image, Spin } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getBlogById, getBlogs } from "../../Services/Blogs";
import dayjs from "dayjs";
import DOMPurify from "dompurify";

function BlogDetailComponent() {
  const navigate = useParams();
  const location = useNavigate();

  const id = navigate.id;

  const { data, isLoading } = useQuery(
    ["blogs", id],
    () => getBlogById({ id }),
    {
      enabled: !!id,
    }
  );

  const { data: totalBlogData } = useQuery(["getBlogs"], () => {
    return getBlogs();
  });

  const sanitizedDescriptionById = {
    __html: DOMPurify.sanitize(data?.description),
  };

  const filteredTotalBlogs = totalBlogData?.filter((blog) => blog.id !== id);

  const handClickNextPage = (id) => {
    location(`/blog-detail/${id}`);
  };

  return (
    <Spin spinning={isLoading} className="w-full h-full">
      <div className="flex justify-center items-center w-full  flex-col   ">
        <div className="flex justify-center items-center w-[100%] object-cover  h-[450px]  img mt-[40px]">
          <Image
            src={data?.image}
            alt="img"
            preview={false}
            height="100%"
            width="100%"
            className="object-cover"
          />
        </div>
        <div className="flex justify-start items-center my-[30px] flex-col w-full max-w-[700px]  px-[20px]">
          <div className="flex justify-start items-center  text-[25px] text-start w-full  font-[400px] my-[20px] ">
            {dayjs(data?.createdAt).format("DD/MM/YYYY")}
          </div>
          <div className="flex justify-start items-center text-[40px] font-[700] my-[40px] ">
            {data?.title}
          </div>
          <div className="flex justify-start items-center text-[20px] font-[400] my-[20px] ">
            <div className="description-container">
              <div dangerouslySetInnerHTML={sanitizedDescriptionById} />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center w-full  px-[70px] tablet:px-[30px]  ">
          <div className="flex justify-center items-center text-[35px] font-[700] mobile:text-[28px] mb-[30px]">
            More Related
          </div>
          <div className="flex justify-center items-center">
            <Button
              type="primary"
              className={`felx items-center justify-center text-[18px] bg-[#AAE127] border-[#AAE127] hover:border-1-solid hover:border-[#AAE127]  rounded-[26px] h-[45px]  mb-[25px] `}
              onClick={() => location(`/free-resoures`)}
            >
              View More
            </Button>
          </div>
        </div>
        <div className="flex justify-start items-center my-[40px] gap-[20px] flex-wrap  px-[15px] w-full h-full">
          {filteredTotalBlogs?.map((item) => {
            const sanitizedDescription = {
              __html: DOMPurify.sanitize(item?.description),
            };

            return (
              <div
                key={item?.id}
                className="flex justify-start items-start flex-col p-[20px] w-full max-w-[350px] border-2 border-solid border-[#77757584] rounded-[26px] cursor-pointer"
                onClick={() => handClickNextPage(item?.id)}
              >
                <div className="flex justify-center items-center w-[300px]  h-[299px] img">
                  <Image
                    src={item?.image}
                    alt="img"
                    preview={false}
                    width="100%"
                    height="100%"
                    className="object-cover"
                  />
                </div>
                <div className="flex justify-start items-start text-[16px] my-[5px] font-[500] text-[#777575]">
                  {dayjs(data?.createdAt).format("DD/MM/YYYY")}
                </div>
                <div className="flex justify-start items-start text-[18px] font-[600] my-[5px]">
                  {item?.title}
                </div>
                <div className="flex justify-start items-start text-[16px] font-[400]">
                  <div className="description-container">
                    <div dangerouslySetInnerHTML={sanitizedDescription} />
                  </div>
                </div>
                <div
                  className="flex justify-start items-center gap-x-[20px] text-[16px] text-[#1869A9] mt-[15px] font-[700]"
                  onClick={() => handClickNextPage(item?.id)}
                >
                  <div>Learn More</div>
                  <div className="mb-[2px]">
                    <ArrowRightOutlined />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Spin>
  );
}

export default BlogDetailComponent;
