import React from "react"
import FindLocationInput from "../shared/findLocationInput/FindLocationInput"

const better = [
  {
    id: "1",
    title: "Find Your Nearest Location & Get A Quote",
    info: "Enter your zip code below to find the nearest SafeGuard branch for an in-home evaluation. ",
  },
  {
    id: "2",
    title: "Get Professional Insulation Done Quickly",
    info: "We work efficiently and with care to get the job done - leaving no mess behind. ",
  },
  {
    id: "3",
    title: "Experience A Difference You Can Truly Feel",
    info: "Enjoy an energy efficient home that stays the perfect temperature year-round.",
  },
]

function BetterInSulation() {
  return (
    <div className="flex justify-center items-center w-full flex-col   mb-[100px] px-[30px] ">
      <div className="flex justify-center items-center  flex-col w-full max-w-[700px] text-center my-[40px] ">
        <div className="flex justify-center items-center text-[42px] font-[600] mobile:text-[30px]">
          Better Insulation in 3 Easy Steps
        </div>
        <div className="flex justify-center items-center text-[18px] text-center w-full max-w-[500px] mobile:text- font-[300px] my-[20px]">
          SafeGuard Insulation is licensed, insured, and with the know-how to
          get it done right, once and for all.
        </div>
      </div>
      <div className="flex justify-center items-center flex-wrap  gap-x-[20px] gap-y-[40px]">
        {better?.map((item) => {
          return (
            <div
              key={item.id}
              className="flex justify-start flex-col h-[470px] items-start bg-[#27AAE1] p-[30px]  rounded-[40px] w-full max-w-[330px]  "
            >
              <div className="flex justify-center items-center text-white text-[45px] w-[45px] font-[700] bg-[#1869A9] px-[30px]  mt-[20px]">
                {item.id}
              </div>
              <div className="flex justify-start items-center text-white text-[28px] font-[500] my-[40px] ">
                {item.title}
              </div>
              <div className="flex justify-start items-center text-white text-[20px] font-[300] pb-[20px] ">
                {item.info}
              </div>
            </div>
          )
        })}
      </div>
      <div className="flex justify-center items-center w-full max-w-[600px] mt-[30px] ">
        <FindLocationInput />
      </div>
    </div>
  )
}

export default BetterInSulation
