import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import ModalForm from "../../Modal/Modal";
import { CloseOutlined } from "@ant-design/icons";

function FindLocationInput() {
  const [inputZipCode, setInputZipCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageModal, setMessageModal] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const cancel = () => {
    setMessageModal(false);
  };

  const redModal = () => {
    setMessageModal(true);
  };

  const zipCodeList = [
    64735, 64738, 64740, 64761, 64763, 64776, 65001, 65010, 65011, 65013, 65014,
    65016, 65017, 65018, 65020, 65023, 65024, 65025, 65026, 65032, 65034, 65035,
    65037, 65039, 65040, 65041, 65043, 65046, 65047, 65048, 65049, 65050, 65051,
    65052, 65053, 65054, 65058, 65059, 65061, 65063, 65064, 65065, 65067, 65068,
    65069, 65072, 65074, 65075, 65076, 65077, 65078, 65079, 65080, 65081, 65082,
    65083, 65084, 65085, 65101, 65109, 65201, 65202, 65203, 65231, 65233, 65237,
    65240, 65248, 65250, 65251, 65255, 65256, 65262, 65274, 65276, 65279, 65284,
    65287, 65301, 65305, 65322, 65323, 65324, 65325, 65326, 65329, 65332, 65333,
    65334, 65335, 65336, 65337, 65338, 65340, 65345, 65347, 65348, 65350, 65351,
    65354, 65355, 65360, 65401, 65443, 65452, 65457, 65459, 65463, 65473, 65486,
    65534, 65536, 65550, 65556, 65559, 65567, 65580, 65582, 65583, 65584, 65590,
    65591, 65613, 65622, 65634, 65650, 65668, 65685, 65724, 65727, 65732, 65735,
    65764, 65767, 65774, 65779, 65783, 65786, 65787,
  ];

  const handleZipCodeCheck = () => {
    const enteredZipCode = parseInt(inputZipCode);
    if (zipCodeList.includes(enteredZipCode)) {
      openModal();
      setInputZipCode("");
    } else {
      redModal();
      setInputZipCode("");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessageModal(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="flex justify-center items-center mt-[30px] w-full ">
        <Form className="flex justify-center items-center w-full">
          <div className="flex justify-center items-center w-full ">
            <Form.Item name="zip code" className="w-full  ">
              <Input
                type="number"
                placeholder="Zip code"
                className="h-[40px] w-full rounded-[26px]"
                value={inputZipCode}
                onChange={(e) => setInputZipCode(e.target.value)}
              />
              <div className="w-full">
                <ModalForm openModal={isModalOpen} setmodal={setIsModalOpen} />
              </div>
              <div className="note_modal">
                <Modal
                  width={500}
                  closable={cancel}
                  open={messageModal}
                  onCancel={cancel}
                  footer={false}
                  className="absolute top-0 right-0"
                  closeIcon={<CloseOutlined className="text-white" />}
                >
                  <div className="flex justify-center items-center text-[24px] w-full h-full bg-red-600 p-[30px] pt-[50px] font-[600] text-white">
                    SafeGuard Insulation doesn't appear to currently service
                    your area, but please give us a call to see how we can help
                    you! (573) 877-7377
                  </div>
                </Modal>
              </div>
            </Form.Item>
            <Button
              className={`felx items-center justify-center text-[16px] bg-[#AAE127] hover:!text-black  hover:!border-1-solid hover:!border-[#AAE127] rounded-[26px] h-[40px] -ml-[70px] mb-[24px] !border-[#AAE127]`}
              onClick={handleZipCodeCheck}
            >
              Find Our Nearest Location
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default FindLocationInput;
