export const financing = {
  page: "Financing",
  title: "PAYMENT PLANS FOR ANY TYPE OF JOB",
  info: "At TFG, we offer several convenient financing options for homeowners. During our free inspection, we will work with you to determine the best financing option that fits your needs.",
  imageUrl: "/Images/fin-1.webp",
  background: "financing",
  backgroundColor: "#1869A9",
  bgColorOpacity: "#3db0e18e",
  buttonColor: "#AAE127",
  button: true,
}
