import { Image, Rate } from "antd";
import React from "react";

function RatingComponent() {
  return (
    <div className="flex justify-center items-center flex-col  w-full  h-full max-h-[600px]  rating_img   ">
      <div className="flex justify-center items-center text-white text-[40px] font-[600] my-[40px] ">
        Total Reviews
      </div>
      <div className="flex justify-between py-[20px] w-full max-w-[650px] mb-[90px] h-full rounded-[20px]   bg-white img px-[20px]   ">
        <div className="flex justify-start items-center flex-col  ">
          <div className="flex justify-center items-center gap-x-[15px] ">
            <div className="flex justify-center items-center text-[100px]  font-[600] ">
              4.9
            </div>
            <div className="flex justify-start items-start flex-col ">
              <div className="flex justify-center items-center text-[24px] font-[500]  text-[#9A9999] ">
                Rated
              </div>
              <div className="flex justify-center items-center capitalize text-[24px] font-[500] ">
                Outstanding
              </div>
            </div>
          </div>
          <div className="flex justify-end items-end text-[50px]">
            <Rate
              disabled
              defaultValue={5}
              className="flex justify-center gap-x-[10px] items-center text-[50px]"
            />
          </div>
        </div>
        <div className="flex justify-end items-end w-full py-[10px]">
          <div className="flex justify-end flex-col items-end">
            <div className="flex justify-end items-end font-medium  text-[18px] text-[#9A9999] ">
              20 reviews
            </div>
            <div className="flex justify-end items-end  gap-x-[10px]">
              <div className="flex justify-end items-end flex-col gap-y-[10px]">
                <div className="flex justify-end items-end  w-[28px] h-[28px]  ">
                  <Image
                    src="/Images/angi.png"
                    alt="img"
                    width="100%"
                    height="100%"
                    preview={false}
                    className="object-cover"
                  />
                </div>
                {/* <div className="flex justify-end items-end w-[28px] h-[28px]">
                  <Image
                    src="/Images/stars.png"
                    alt="img"
                    width="100%"
                    height="100%"
                    preview={false}
                    className="object-cover"
                  />
                </div>
                <div className="flex justify-end items-end text-[20px] font-[500]">
                  other
                </div> */}
              </div>
              <div className="flex justify-center items-center flex-col ">
                {/* <div className="flex justify-center items-center">
                  <Rate
                    disabled
                    defaultValue={5}
                    className="flex justify-center items-center text-[24px]"
                  />
                </div>
                <div className="flex justify-center items-center mt-1">
                  <Rate
                    disabled
                    defaultValue={5}
                    className="flex justify-center items-center text-[24px]"
                  />
                </div> */}
                <div className="flex justify-center items-center mt-1">
                  <Rate
                    disabled
                    defaultValue={5}
                    className="flex justify-center items-center text-[24px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RatingComponent;
