import React from "react"
import SharedOurValueComponent from "../shared/SharedOurValueComponent/SharedOurValueComponent"
import PageTitle from "../shared/PageTitle/PageTitle"

const values = [
  {
    id: 1,
    URL: "/Images/chat-check-fill.png",
    title: "Expert Advice",
    info: " You’ll be paired up with an expert who knows and communicates facts about your issue.",
  },
  {
    id: 2,
    URL: "/Images/restart-line.png",
    title: "Efficient Service",
    info: "We prioritize your time. What matters to you, matters to us.",
  },
  {
    id: 3,
    URL: "/Images/award-line.png",
    title: "Quality First ",
    info: "Your comfort is our true benchmark, and that’s why we use proven tools and methods.",
  },
]

function OurValues() {
  return (
    <div className="flex flex-col justify-center items-center flex-wrap w-full bg-[#F5F5F5] p-[10px]">
      <PageTitle title={"Our Values"} />

      <SharedOurValueComponent values={values} />
    </div>
  )
}

export default OurValues
