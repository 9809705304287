import React from "react"

function HappyCustomer() {
  return (
    <div className="flex justify-center items-center text-center w-full flex-col text-white h-full max-h-[700px] bg_happy_customer  py-[80px] px-[20px] mb-[5px] ">
      <div className="flex justify-center items-center text-[32px] font-[700] [20px] w-full max-w-[500px] mobile:text-[28px]">
        {" "}
        Hear From A Happy Customer in Mid-Missouri{" "}
      </div>
      <div className="flex justify-center items-center text-[18px]  w-full max-w-[600px] my-[30px] font-[400px]">
        {" "}
        On the initial call Chase answered all my questions and provided a clear
        description of the work to be performed along with the code
        requirements.
      </div>
      <div className="flex flex-col justify-center items-center text-[18px]  font-[400px]">
        <p>-Jennifer F.</p>
        <p>Fulton, MO</p>
      </div>
    </div>
  )
}

export default HappyCustomer
