import { Button, Image } from "antd"
import React, { useState } from "react"
import ModalForm from "../../Modal/Modal"

function PageTopComponent({ componentdata }) {
  // console.log("Page data", componentdata)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  return (
    <div>
      <ModalForm openModal={isModalOpen} setmodal={setIsModalOpen} />
      <div
        className={
          (componentdata.background === "about" &&
            "flex justify-start items-start  tablet:text-center tablet:justify-center tablet:items-center  h-[600px] mt-[40px] About-img w-full ") ||
          (componentdata.background === "financing" &&
            "flex justify-start items-start  tablet:text-center tablet:justify-center tablet:items-center  h-[600px] mt-[40px] Finacing-img  w-full ") ||
          (componentdata.background === "resources" &&
            "flex justify-start items-start  tablet:text-center tablet:justify-center tablet:items-center  h-[600px] mt-[40px] free-resources  w-full ") ||
          (componentdata.background === "reviews" &&
            "flex justify-start items-start  tablet:text-center tablet:justify-center tablet:items-center  h-[600px] mt-[40px] ReviewsSafeGuard_img w-full ") ||
          (componentdata.background === "services" &&
            "flex justify-start items-start  tablet:text-center tablet:justify-center tablet:items-center  h-[600px] mt-[40px] service-img w-full")
        }
      >
        <div
          className={
            (componentdata.background === "about" &&
              `flex justify-end flex-col  items-end  px-[30px]  tablet:justify-center tablet:items-center tablet:text-center bg-[#AAE127] tablet:bg-[#a9e12780] pt-[100px] pb-[60px] mobile:py-[130px]  w-full max-w-[600px] tablet:max-w-full h-[600px]`) ||
            (componentdata.background === "financing" &&
              `flex justify-end flex-col  items-end  px-[30px]  tablet:justify-center tablet:items-center tablet:text-center bg-[#1B75BC] tablet:bg-[#1b76bc90] pt-[100px] pb-[60px] mobile:py-[130px]  w-full max-w-[600px] tablet:max-w-full h-[600px]`) ||
            (componentdata.background === "resources" &&
              `flex justify-end flex-col  items-end  px-[30px]  tablet:justify-center tablet:items-center tablet:text-center bg-[#3DB0E1] tablet:bg-[#3db0e19d] pt-[100px] pb-[60px] mobile:py-[130px]  w-full max-w-[600px] tablet:max-w-full h-[600px]`) ||
            (componentdata.background === "reviews" &&
              `flex justify-end flex-col  items-end  px-[30px]  tablet:justify-center tablet:items-center tablet:text-center bg-[#ADCB41] tablet:bg-[#adcb4180] pt-[100px] pb-[60px] mobile:py-[130px]  w-full max-w-[600px] tablet:max-w-full h-[600px]`) ||
            (componentdata.background === "services" &&
              `flex justify-end flex-col  items-end  px-[30px]  tablet:justify-center tablet:items-center tablet:text-center bg-[#3DB0E1] tablet:bg-[#3db0e18e] pt-[100px] pb-[60px] mobile:py-[130px]  w-full max-w-[600px] tablet:max-w-full h-[600px]`)
          }
        >
          <div className="flex justify-end items-end flex-col  tablet:justify-center tablet:items-center mt-[150px]  ">
            <div className="w-full">
              <div
                className={
                  componentdata.background === "financing"
                    ? `flex justify-start items-start text-white text-[25px] font-[400] text-start tablet:text-center tablet:justify-center tablet:items-center `
                    : `flex justify-start items-start text-black text-[25px] font-[400] text-start tablet:text-center tablet:justify-center tablet:items-center `
                }
              >
                {componentdata?.page}
              </div>

              <div
                className={
                  componentdata.background === "financing"
                    ? "flex justify-start items-start text-[35px] font-[700] uppercase mt-[30px] w-full max-w-[400px] tablet:text-center tablet:justify-center tablet:items-center mobile:text-[33px] text-white  "
                    : "flex justify-start items-start text-[35px] font-[700] uppercase mt-[30px] w-full max-w-[400px] tablet:text-center tablet:justify-center tablet:items-center mobile:text-[33px] text-black  "
                }
              >
                {componentdata?.title}
              </div>
              <div
                className={
                  componentdata.background === "financing"
                    ? "flex justify-start items-center text-[18px] font-[400] tablet:text-center w-full max-w-[400px] mt-[30px] text-white"
                    : "flex justify-start items-center text-[18px] font-[400] tablet:text-center w-full max-w-[400px] mt-[30px] text-black"
                }
              >
                {componentdata?.info}
              </div>

              <div className="flex justify-start items-start w-full max-w-[250px] tablet:max-w-full  !tablet:text-center !tablet:justify-center !tablet:items-center  mt-[30px] ">
                {componentdata.button && (
                  <Button
                    onClick={openModal}
                    className={`flex items-center justify-center text-[18px] bg-[#AAE127] border-white hover:!border-white  hover:!bg-[#AAE127] !w-full  hover:border-1-solid  rounded-[26px] h-[45px]   uppercase  font-[700] hover:!text-[#000] `}
                  >
                    Request A Quote
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center w-[100%] object-cover tablet:hidden  h-[600px]  img ">
          <Image
            src={componentdata?.imageUrl}
            alt="img"
            preview={false}
            height="100%"
            width="100%"
            className="object-cover"
          />
        </div>
      </div>
    </div>
  )
}

export default PageTopComponent
