import React, { useEffect } from "react";
import MainLayout from "../component/shared/layout/MainLayout";
import LadyComponent from "../component/shared/LadyComponent/LadyComponent";
import CertifiedComponent from "../component/shared/Certified/CertifiedComponent";
import FreeQuoteToday from "../component/shared/FreeQuote/FreeQuoteToday";
import FreeResoueseCard from "../component/FreeResourseCard/FreeResoueseCard";
import PageTopComponent from "../component/shared/PageTopComponet/PageTopComponent";
import { Resource } from "../Services/FreeResources";
import { useLocation } from "react-router-dom";

function FreeResources() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <MainLayout>
        <PageTopComponent componentdata={Resource} />
        <FreeResoueseCard />
        <LadyComponent />
        <CertifiedComponent />
        <FreeQuoteToday />
      </MainLayout>
    </div>
  );
}

export default FreeResources;
