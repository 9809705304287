import React, { useEffect } from "react";
import MainLayout from "../component/shared/layout/MainLayout";
import ServicesPageTopComponent from "../component/shared/ServicesPageTopComponent/ServicesPageTopComponent";
import ServicesInfo from "../component/shared/ServicesInfo/ServicesInfo";
import ServicesInfoTwo from "../component/shared/ServicesInfoTwo/ServicesInfoTwo";
import SharedOurValueComponent from "../component/shared/SharedOurValueComponent/SharedOurValueComponent";
import ButtonContact from "../component/shared/ButtonContact/ButtonContact";
import LadyComponent from "../component/shared/LadyComponent/LadyComponent";
import CertifiedComponent from "../component/shared/Certified/CertifiedComponent";
import FreeQuoteToday from "../component/shared/FreeQuote/FreeQuoteToday";

import PageTitle from "../component/shared/PageTitle/PageTitle";

import {
  second_info,
  service_spray,
  servies_info,
  title,
  values,
} from "../Services/ServicesBlowerDoorTesting";
import { useLocation } from "react-router-dom";

function BlowerDoorTesting() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <MainLayout>
      <ServicesPageTopComponent pageData={service_spray} />

      <ServicesInfo first={servies_info} />
      <ServicesInfoTwo second={second_info} />
      <PageTitle title={title} />
      <SharedOurValueComponent values={values} />
      <ButtonContact />
      <LadyComponent />
      <CertifiedComponent />
      <FreeQuoteToday />
    </MainLayout>
  );
}

export default BlowerDoorTesting;
