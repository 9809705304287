import React from "react"

import SharedOurValueComponent from "../shared/SharedOurValueComponent/SharedOurValueComponent"
import ButtonContact from "../shared/ButtonContact/ButtonContact"
import PageTitle from "../shared/PageTitle/PageTitle"
const values = [
  {
    id: 1,
    URL: "/Images/chat-check-fill.png",
    title: "Quick Installation",
    info: "Installation is quicker than most types of insulation.",
  },
  {
    id: 2,
    URL: "/Images/restart-line.png",
    title: "Long-Lasting Insulation",
    info: "Can maintain effectiveness for decades.",
  },
  {
    id: 3,
    URL: "/Images/award-line.png",
    title: "Highly Cost Effecient",
    info: "Costs less due to quick and easy installation.",
  },
  {
    id: 4,
    URL: "/Images/chat-check-fill.png",
    title: "Versatile",
    info: " Installation is quicker than most types of insulation.",
  },
  {
    id: 5,
    URL: "/Images/restart-line.png",
    title: "Naturally FIre-Resistant",
    info: "Makes your home more resistant to fire.",
  },
  {
    id: 6,
    URL: "/Images/award-line.png",
    title: "Eco-Friendly",
    info: "Made from recycled materials.",
  },
]

function BenefitBattInsulation() {
  return (
    <div className="flex justify-center flex-col items-center w-full px-[30px]">
      <PageTitle title={"Benefits of Batt Insulation"} />

      <SharedOurValueComponent values={values} />
      <ButtonContact />
    </div>
  )
}

export default BenefitBattInsulation
