export const service_spray = {
  title: "Solar attic fan",
  url: "/Images/s-bg-2.png",
  info: "Attic fans play an essential role in discharging hot air trapped in the attic, regulating extreme temperatures. If your HVAC system is located in your attic, this type of  fan can make a dramatic difference on the lifespan of your unit.  Solar fans require no electricity, making them an excellent eco-friendly option to lower energy costs year-round.",
}
export const madeFrom = [
  {
    id: 1,
    url: "/Images/made-9.png",
    title: "QuietCool® Attic Fan",
  },
  {
    id: 2,
    url: "/Images/made-10.png",
    title: "Remington® Attic Fan",
  },
]

export const servies_info = {
  title: "Benefits of Solar Attic fans",
  url: "/Images/sa-1.png",
  info: "If you’ve been researching attic ventilation, you’ve probably heard about attic fans.  Regardless of what type of insulation you have in your attic, ventilation helps to control the humidity and temperature of your attic space.  Humid, hot air leads to mold and mildew growth that can destroy the foundation of your attic, degrade your roof and lower your home’s value.Many homes, especially older homes, lack adequate ventilation in the attic. Adding baffles is a no-brainer, but without roof vents or ventilated soffits, your attic may be lacking in ability to properly discharge hot, humid air.  If you have an HVAC system located in your attic, or have ductwork running through your attic, an attic fan can help improve the efficiency of your unit.  Regardless of the insulation on your ducts, high heat and humidity lead to more strain and a shorter unit lifespan. Air traveling through exposed ducts heats up quickly and can contribute to inadequately cooled rooms.",
}
export const second_info = {
  title: "Why Solar?",
  url: "/Images/sa-2.png",
  info: "Solar attic fans require less labor to install due to the fact that they do not need to be wired to electricity or another form of energy.  They require no maintenance, and last on average for 15 years or more.  Solar attic fans are an excellent, environmentally and economically friendly way to lower the temperature and humidity in your attic during the summer and can therefore contribute to much lower energy bills.Along with proper insulation, solar attic fans can give you the comfort you desire in your home.  Contact our experts today for a free inspection to see if one or more solar attic fans are the right solution to your home project.",
}

export const values = [
  {
    id: 1,
    URL: "/Images/close-circle-line.png",
    title: "Prevent Mold and Mildew",
    info: "Increased attic ventilation and temperature regulation can halt mold growth.",
  },
  {
    id: 2,
    URL: "/Images/history-line.png",
    title: "Long-Lasting Benefits",
    info: "Solar fans can last for 15 years or more and require no maintenance.",
  },
  {
    id: 3,
    URL: "/Images/windy-line.png",
    title: "Increase HVAC Lifespan",
    info: "Lower attic temperatures increase efficiency of attic ducts and increase efficiency of your HVAC unit.",
  },
  {
    id: 4,
    URL: "/Images/heart-3-line.png",
    title: "Whole Home Comfort",
    info: "Lower attic temperature increases comfort in your home.",
  },
  {
    id: 5,
    URL: "/Images/icon1.png",
    title: "Save on Energy Bills",
    info: "If you have attic ductwork, regulating temperature can reduce energy bills.",
  },
  {
    id: 6,
    URL: "/Images/icon2.png",
    title: "Energy Efficient",
    info: "Solar power is an environmentally friendly choice.",
  },
]

export const title = "Benefits of Solar Attic Fans"
