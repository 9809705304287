export const service_spray = {
  title: "Blower Door Testing",
  url: "/Images/s-bg-2.png",
  info: "Whether your attic has experienced pest problems in the past or present, or you own an older home with an outdated and degraded insulation type, you may be an excellent candidate for insulation removal.  Animal excrement and outdated, questionable chemicals are never a good thing to have lurking over your head.  Starting fresh can give you relief from allergies, pesticides, and other contaminates as well as provide a fresh canvas for air sealing your attic.",
}

export const servies_info = {
  title: "What is Blower Door Testing?",
  url: "/Images/sd-1.png",
  info: "A blower door test is something most people have never heard of, but is becoming very prominent in building engineering.  In short, a blower door test uses a manometer, a fan, and a computer to determine the air exchange in your home, measured in CFM (Cubic Feet per Minute).  This gives a quantifiable value to your home’s energy efficiency by detecting how air-tight your home is.Your home’s CFM can not only quantify how leaky your home is, but can also allow our experts to determine the source of any air leakage so it can be addressed.  Blower doors utilize a powerful fan to reverse airflow through your house, pulling in outside air through any uninsulated or open cavities, which allows thermal cameras to do a better job at detecting thermal differences in these problem areas.  Having a quantifiable number of air exchanges before and after you complete any home insulation project allows you to have a demonstrable difference in energy savings through reducing air gaps.",
}
export const second_info = {
  title: "Benefits of Blower Door Testing",
  url: "/Images/sr-2.png",
  info: "When it comes to retrofitting older homes with insulation, there are often problem areas that we cannot see.  When the temperature outside is either very hot or very cold, thermal cameras can be effective at detecting air leakage but are not perfect.  Blower door testing bridges this gap by quantifying air leakage and making thermal gaps identifiable on a thermal camera regardless of the temperature outside.",
}

export const values = [
  {
    id: 1,
    URL: "/Images/close-circle-line.png",
    title: "Remove Contaminants",
    info: "Removes animal excrement, allergens, and outdated or damaged insulation..",
  },
  {
    id: 2,
    URL: "/Images/history-line.png",
    title: "Long-Lasting Benefits",
    info: "Installing new materials that are designed to last longer increases attic efficiency and protects lifetime R-Value",
  },
  {
    id: 3,
    URL: "/Images/windy-line.png",
    title: "Improves Indoor Air Quality",
    info: "Removing contaminated insulation improves air quality in your home, and provides an opportunity for air sealing.",
  },
  {
    id: 4,
    URL: "/Images/icon7.png",
    title: "Complete Air Sealing",
    info: " Creates a blank canvas for air sealing your attic or crawlspace.",
  },
  {
    id: 5,
    URL: "/Images/icon1.png",
    title: "Save on Energy Bills",
    info: "Ineffective insulation provides little to no benefit. Starting over can improve efficiency..",
  },
  {
    id: 6,
    URL: "/Images/home-2-line.png",
    title: "Increase Home Value",
    info: "New Insulation increases your home’s resale value.",
  },
]

export const title = "Benefits of Insulation Removal"
