import { Image } from "antd";
import React from "react";

function CertifiedComponent() {
  return (
    <div className="flex justify-center items-center flex-col w-full py-[20px] bg-[#1869A9] px-[30px]">
      <div className="flex justify-center items-center text-center mobile:text-[28px]  text-white text-[33px] font-[600px]">
        Certified & Approved
      </div>
      <div className="flex justify-center items-center flex-wrap gap-[70px] tablet_LG:gap-[40px] py-[50px] ">
        <div className="flex justify-center items-center  w-[186px] h-[105px] img ">
          <Image
            src="/Images/c-1.png"
            alt="certifed-company"
            preview={false}
            className="object-cover"
          />
        </div>
        <div className="flex justify-center items-center  w-[114px] h-[117px] img ">
          <Image
            src="/Images/c-2.png"
            alt="certifed-company"
            preview={false}
            className="object-cover"
          />
        </div>
        <div className="flex justify-center items-center  w-[134px] h-[127px] img ">
          <Image
            src="/Images/c-3.png"
            alt="certifed-company"
            preview={false}
            className="object-cover"
          />
        </div>
        <div className="flex justify-center items-center  w-[143px] h-[130px] img ">
          <Image
            src="/Images/c-4.png"
            alt="certifed-company"
            preview={false}
            className="object-cover"
          />
        </div>
        <div className="flex justify-center items-center  w-[252px] h-[120px] img ">
          <Image
            src="/Images/c-5.png"
            alt="certifed-company"
            preview={false}
            className="object-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default CertifiedComponent;
