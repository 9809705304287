import { Image, Spin } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getBlogs } from "../../Services/Blogs";
import { useQuery } from "react-query";
import DOMPurify from "dompurify";
import dayjs from "dayjs";

function FreeResoueseCard() {
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(["getBlogs"], () => {
    return getBlogs();
  });

  const handClickNextPage = (id) => {
    navigate(`/blog-detail/${id}`);
  };

  return (
    <Spin spinning={isLoading} className="w-full h-full">
      <div className="flex justify-center items-center my-[70px] gap-[20px] flex-wrap px-[30px] w-full h-full">
        {data?.map((item) => {
          const sanitizedDescription = {
            __html: DOMPurify.sanitize(item?.description),
          };

          return (
            <div
              key={item?.id}
              className="flex justify-start items-start flex-col p-[20px] w-full max-w-[350px] border-2 border-solid border-[#77757584] rounded-[26px] cursor-pointer "
              onClick={() => handClickNextPage(item?.id)}
            >
              <div className="flex justify-center items-center w-[300px]  h-[299px] img">
                <Image
                  src={item?.image}
                  alt="img"
                  preview={false}
                  width="100%"
                  height="100%"
                  className="object-cover"
                />
              </div>
              <div className="flex justify-start items-start text-[16px] my-[5px] font-[500] text-[#777575]">
                {dayjs(item?.createdAt).format("DD/MM/YYYY")}
              </div>
              <div className="flex justify-start items-start text-[18px] font-[600] my-[5px]">
                {item.title}
              </div>
              <div className="flex justify-start items-start text-[16px] font-[400]">
                <div className="description-container">
                  <div dangerouslySetInnerHTML={sanitizedDescription} />
                </div>
              </div>

              <div
                className="flex justify-start items-center gap-x-[20px] text-[16px] text-[#1869A9] mt-[15px] font-[700]"
                onClick={() => handClickNextPage(item.id)}
              >
                <div>Learn More</div>
                <div className="mb-[2px]">
                  <ArrowRightOutlined />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Spin>
  );
}

export default FreeResoueseCard;
