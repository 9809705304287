import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

function SafeBet() {
  const navigate = useNavigate();

  return (
    <div
      className="flex justify-center items-center gap-x-[40px] tablet_LG:flex-col my-[50px] px-[30px] bg-cover bg-no-repeat"
      style={{ backgroundImage: "url(/Images/dot.png)" }}
    >
      <div className="flex justify-start items-start flex-col w-full max-w-[500px] tablet_LG:justify-center tablet_LG:items-center  tablet_LG:text-center ">
        <div className="flex justify-start items-start text-start text-[42px] font-[600]  leading-normal mb-[15px] tablet_LG:justify-center  tablet_LG:items-center tablet_LG:text-center mobile:text-[30px] ">
          Why We’re A Safe Bet
        </div>
        <div className="flex justify-center items-center text-[18px] font-[500] text-start mb-[40px] tablet_LG:text-center">
          SafeGuard Insulation is licensed, insured, and with the know-how to
          get it done right, once and for all.
        </div>
        <div className="flex justify-center items-center tablet_LG:hidden">
          <Button
            onClick={() => navigate("/contact")}
            className={`felx items-center justify-center text-[18px] bg-[#AAE127] border-[#AAE127] hover:!text-black hover:border-1-solid hover:border-[#AAE127]  rounded-[26px] h-[45px]  mb-[25px] `}
          >
            Schedule Your Free Inspection
          </Button>
        </div>
      </div>
      <div className="flex justify-start items-start flex-col  tablet_LG:justify-center tablet_LG:items-center tablet_LG:text-center w-full max-w-[500px] gap-y-[30px]">
        <div className="flex justify-start items-start flex-col tablet_LG:justify-center  tablet_LG:items-center tablet_LG:text-center">
          <div className="flex justify-start items-center text-[24px] font-[600] text-[#1868a7] tablet_LG:justify-center  tablet_LG:items-center tablet_LG:text-center mobile:text-[20px] ">
            Unbeatable Skills and Training
          </div>
          <div className="flex justify-start items-center text-[16px] font-[500]  tablet_LG:justify-center  tablet_LG:items-center tablet_LG:text-center leading-normal mobile:text-[14px] ">
            Our experts deliver results that stand apart. Rely on a team that’s
            committed to next-level attention to detail.
          </div>
        </div>
        <div className="flex justify-start items-start flex-col tablet_LG:justify-center  tablet_LG:items-center tablet_LG:text-center ">
          <div className="flex justify-start items-center text-[24px] tablet_LG:justify-center  tablet_LG:items-center tablet_LG:text-center font-[600] text-[#1868a7] mobile:text-[20px] ">
            We Leave Your Home Neat And Tidy
          </div>
          <div className="flex justify-start items-center text-[16px] font-[500] tablet_LG:justify-center  tablet_LG:items-center tablet_LG:text-center leading-normal mobile:text-[14px]  ">
            You don’t sweep your problems under the rug, and neither do we.
            Attic and crawl space tasks might get messy, but rest assured, we'll
            leave your home as tidy as when we arrived.il.
          </div>
        </div>
        <div className="flex justify-start items-start flex-col tablet_LG:justify-center  tablet_LG:items-center tablet_LG:text-center ">
          <div className="flex justify-start items-center text-[24px] tablet_LG:justify-center  tablet_LG:items-center tablet_LG:text-center font-[600] text-[#1868a7] mobile:text-[20px] ">
            Energy Efficiency You Can Count On
          </div>
          <div className="flex justify-start items-center text-[16px] font-[500] tablet_LG:justify-center  tablet_LG:items-center tablet_LG:text-center leading-normal mobile:text-[14px]  ">
            Our best-in-class installation services ensure your home's comfort,
            while also boosting its energy performance for a greener, more
            cost-effective living environment.
          </div>
          <div className="hidden tablet_LG:block  mt-[30px]">
            <Button
              className={`felx items-center justify-center text-[18px] bg-[#AAE127] border-[#AAE127] hover:text-[#AAE127]  hover:border-1-solid hover:border-[#AAE127] hover:bg-white  rounded-[26px] h-[45px]  mb-[25px] `}
            >
              Schedule Your Free Inspection
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SafeBet;
