import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const percentage = [
  {
    id: 1,
    percentage: "40%",
    title: "Unhealthy Living ",
    info: "40% of homes have poor indoor air quality. (EPA)",
  },
  {
    id: 2,
    percentage: "90%",
    title: "Poorly Insulated",
    info: "90% of US Homes are lacking proper insulation.",
  },
  {
    id: 3,
    percentage: "50%",
    title: "Heating & Cooling Bills",
    info: "Maintenance, upgrades may reduce energy by 50%. (DOE)",
  },
  {
    id: 4,
    percentage: "2-5x",
    title: "Indoor Air Pollution",
    info: "Indoor air is 2-5 times more polluted than outdoor air.",
  },
];

function PercentageABS() {
  const navigate = useNavigate();

  return (
    <div
      className="flex justify-center items-center w-full p-[40px] bg-no-repeat bg-cover"
      style={{ backgroundImage: "url(/Images/green.png)" }}
    >
      <div className="flex justify-center items-center flex-col bg-[#1869A9] p-[30px] rounded-[26px] w-full">
        <div className="flex justify-center items-center text-center flex-col text-[35px] font-[600] leading-normal  text-white">
          <div>The ABC’s </div>
          <div>Attics, Basements, Crawl Spaces </div>
        </div>
        <div className="flex justify-center items-center flex-wrap gap-x-[20px] gap-y-[20px] my-[40px]">
          {percentage?.map((item) => {
            return (
              <div
                key={item.id}
                className="flex justify-center items-center flex-col p-[15px]"
              >
                <div
                  className="flex justify-center items-center w-[153px] h-[153px] text-[48px] font-[700]
                 text-white rounded-full bg-[#a9e12746]"
                >
                  {item.percentage}
                </div>
                <div className="flex justify-center items-center text-[20px] font-[600] text-white leading-normal my-[15px]">
                  {item.title}
                </div>
                <div className="flex justify-center items-center  text-center w-full max-w-[225px]  text-white text-[16px]">
                  {item.info}
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-center items-center">
          <Button
            onClick={() => navigate("/contact")}
            className={`felx items-center justify-center text-[18px] bg-[#AAE127] border-[#AAE127] hover:!bg-[#AAE127] hover:!text-white  rounded-[26px] h-[45px]  mb-[25px] `}
          >
            Schedule Your Free Inspection
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PercentageABS;
