import { Image } from "antd";
import React from "react";

function AboutWork() {
  return (
    <div className="flex justify-center items-center flex-col w-full px-[90px] tablet:px-[30px] ">
      <div className="flex justify-center items-center flex-col w-full">
        <div className="flex justify-center items-center text-[40px]  font-[600]">
          How We Work
        </div>
        <div className="flex justify-center items-center text-[18px] font-[400]">
          Achieve results in four easy steps.
        </div>
      </div>
      <div className="flex justify-center items-center gap-x-[50px] tablet:hidden mx-[80px] w-full ">
        <div className="flex justify-start items-start flex-col  gap-y-[130px]  ">
          <div className="flex justify-start items-start flex-col">
            <div className="flex justify-start items-start text-[25px]  font-[600]">
              Inspect With The Right Tools
            </div>
            <div className="flex justify-start items-center text-[18px] font-[400p]">
              We bring the right tools, such as thermal cameras, blower doors,
              and moisture meters - which help us to identify and quantify the
              trouble areas in your home.
            </div>
          </div>
          <div className="flex justify-start items-center w-[142px] h-[142px] ">
            <Image
              src="/Images/icon-3.png"
              alt="icon"
              preview={false}
              width="100%"
              height="100%"
              className="object-cover"
            />
          </div>
          <div className="flex justify-start items-start flex-col">
            <div className="flex justify-start items-start text-[25px] font-[600]">
              Fast Installation
            </div>
            <div className="flex justify-start items-center text-[18px] font-[400p]">
              With a top-notch team poised at the ready, we will complete the
              installation and bring your home to its fullest potential, while
              taking our time to achieve quality results we can stand by.
            </div>
          </div>
          <div className="flex justify-start items-center w-[142px] h-[142px]  mb-[40px]">
            <Image
              src="/Images/icon-4.png"
              alt="icon"
              preview={false}
              width="100%"
              height="100%"
              className="object-cover"
            />
          </div>
        </div>
        <div className="flex justify-center items-center flex-col gap-y-[200px] w-[0px] mr-[40px]  border-2 bordre-solid my-[40px] border-[#AAE127] py-[80px] ">
          <div className="flex justify-center items-center text-center w-[60px] bg-white h-[60px] rounded-full">
            1 <br /> Step
          </div>
          <div className="flex justify-center items-center text-center w-[60px] bg-white h-[60px] rounded-full">
            2 <br /> Step
          </div>
          <div className="flex justify-center items-center text-center w-[60px] bg-white h-[60px] rounded-full">
            3 <br /> Step
          </div>
          <div className="flex justify-center items-center text-center w-[60px] bg-white h-[60px] rounded-full">
            4 <br /> Step
          </div>
        </div>
        <div className="flex justify-start items-start flex-col gap-y-[130px] ">
          <div className="flex justify-start items-center w-[142px]  h-[142px]">
            <Image
              src="/Images/icon-1.png"
              alt="icon"
              preview={false}
              width="100%"
              height="100%"
              className="object-cover"
            />
          </div>
          <div className="flex justify-start items-start flex-col ">
            <div className="flex justify-start items-start text-[25px] font-[600]">
              Assess and Quote
            </div>
            <div className="flex justify-start items-center text-[18px] font-[400p]">
              With a top-notch team poised at the ready, we will complete the
              installation and bring your home to its fullest potential, while
              taking our time to achieve quality results we can stand by.
            </div>
          </div>
          <div className="flex justify-start items-center w-[142px] h-[142px]  ">
            <Image
              src="/Images/icon-2.png"
              alt="icon"
              preview={false}
              width="100%"
              height="100%"
              className="object-cover"
            />
          </div>
          <div className="flex justify-start items-start flex-col ">
            <div className="flex justify-start items-start text-[25px] font-[600]">
              Comfort and Savings
            </div>
            <div className="flex justify-start items-center text-[18px] font-[400p]">
              Enjoy the comfort and savings of having a well-insulated and high
              performance home. With our limited warranty, you can be sure that
              our air sealing, insulation and encapsulation packages live up to
              their promise.y.
            </div>
          </div>
        </div>
      </div>
      <div className="hidden tablet:block  my-[60px]    ">
        <div className="flex justify-center items-center w-full px-[30px] ">
          <div className="flex justify-center items-center w-[0px] border-[#AAE127] border-solid border-2 h-[1200px] absolute "></div>
          <div className="flex justify-center items-center flex-col  gap-y-[90px] relative ">
            <div className="flex justify-center items-center bg-white py-[20px] flex-col gap-y-[30px]">
              <div className="flex justify-center items-center w-[142px]  h-[142px]">
                <Image
                  src="/Images/icon-1.png"
                  alt="icon"
                  preview={false}
                  width="100%"
                  height="100%"
                  className="object-cover"
                />
              </div>
              <div className="flex justify-center items-center text-center py-[20px] flex-col">
                <div className="flex justify-center items-center text-[25px]  font-[600]">
                  Inspect With The Right Tools
                </div>
                <div className="flex justify-center items-center text-[18px] font-[400p]">
                  We bring the right tools, such as thermal cameras, blower
                  doors, and moisture meters - which help us to identify and
                  quantify the trouble areas in your home.
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center bg-white flex-col py-[20px] gap-y-[30px] ">
              <div className="flex justify-center items-center w-[142px] h-[142px] ">
                <Image
                  src="/Images/icon-3.png"
                  alt="icon"
                  preview={false}
                  width="100%"
                  height="100%"
                  className="object-cover"
                />
              </div>
              <div className="flex justify-center items-center text-center flex-col ">
                <div className="flex justify-center items-center text-[25px] font-[600]">
                  Assess and Quote
                </div>
                <div className="flex justify-center items-center text-[18px] font-[400p]">
                  With a top-notch team poised at the ready, we will complete
                  the installation and bring your home to its fullest potential,
                  while taking our time to achieve quality results we can stand
                  by.
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center bg-white flex-col py-[20px] gap-y-[30px]">
              <div className="flex justify-start items-center w-[142px] h-[142px]  ">
                <Image
                  src="/Images/icon-2.png"
                  alt="icon"
                  preview={false}
                  width="100%"
                  height="100%"
                  className="object-cover"
                />
              </div>
              <div className="flex justify-center items-center text-center flex-col">
                <div className="flex justify-center items-center text-[25px] font-[600]">
                  Fast Installation
                </div>
                <div className="flex justify-center items-center text-[18px] font-[400p]">
                  With a top-notch team poised at the ready, we will complete
                  the installation and bring your home to its fullest potential,
                  while taking our time to achieve quality results we can stand
                  by.
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center bg-white flex-col py-[20px] gap-y-[30px] ">
              <div className="flex justify-start items-center w-[142px] h-[142px]  mb-[40px]">
                <Image
                  src="/Images/icon-4.png"
                  alt="icon"
                  preview={false}
                  width="100%"
                  height="100%"
                  className="object-cover"
                />
              </div>
              <div className="flex justify-center items-center text-center flex-col ">
                <div className="flex justify-center items-center text-[25px] font-[600]">
                  Comfort and Savings
                </div>
                <div className="flex justify-start items-center text-[18px] font-[400p]">
                  Enjoy the comfort and savings of having a well-insulated and
                  high performance home. With our limited warranty, you can be
                  sure that our air sealing, insulation and encapsulation
                  packages live up to their promise.y.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AboutWork;
