import React from "react"

function PageTitle({ title }) {
  return (
    <div className="flex justify-center items-center text-[40px] my-[40px] font-[700px] text-center mobile:text-[35px]">
      {title}
    </div>
  )
}

export default PageTitle
