import React from "react"

import { Image } from "antd"
import FindLocationInput from "../findLocationInput/FindLocationInput"

function MadeFrom({ madefrom, title }) {
  return (
    <div className="flex justify-center items-center py-[50px] px-[80px] mobile:px-[20px]  bg-[#AAE127] w-full">
      <div className="flex justify-center items-center w-full bg-[#1869A9FC] px-[20px]  rounded-[21px] flex-col py-[30px] ">
        <div className="flex justify-center items-center text-[40px] text-center font-[700]">
          {title?.title}
        </div>
        <div className="flex justify-center  mt-[15px] my-[30px] items-center text-center text-[20px] font-[400]">
          {" "}
          {title?.info}
        </div>
        <div className="flex justify-center flex-wrap items-center  mb-[40px] w-full gap-[30px]">
          {madefrom?.map((item) => {
            return (
              <div
                key={item.id}
                className="flex justify-center flex-col gap-y-[20px] items-center bg-white w-full max-w-[310px] p-[30px] rounded-[24px]"
              >
                <Image
                  src={item?.url}
                  alt="img"
                  preview={false}
                  className="object-cover"
                />
                <div className="flex justify-center items-center text-[20px] font-[400]">
                  {item?.title}
                </div>
              </div>
            )
          })}
        </div>
        <div className="fex justify-center items-center">
          <FindLocationInput />
        </div>
      </div>
    </div>
  )
}

export default MadeFrom
