import { Button } from "antd";
import React from "react";

function FinancingCheckOption() {
  return (
    <div className="flex justify-center items-center w-full px-[70px] tablet:px-[0px] my-[50px]  flex-col ">
      <div className="flex justify-center items-center bg-[#AAE127] py-[20px] rounded-[24px] flex-col w-full px-[30px] tablet:rounded-[0px] ">
        <div className="flex justify-center items-center text-center text-[42px] font-[700] my-[30px] mobile:text-[28px]  tablet:text-[35px] ">
          Check Options Now, No <br /> Impact to Your Credit Score
        </div>
        <div className="flex justify-center items-center text-[20px] font-[400] my-[40px] text-center w-full  ">
          With competitive rates and fast pre-qualifying, we make financing your
          next project a breeze. Let us work with you to set a reasonable budget
          and find a monthly payment that works for you.
        </div>
        <div className="flex justify-center items-center">
          <Button
            type="primary"
            className={`felx items-center justify-center text-[18px] !bg-[#AAE127] !border-1-solid !border-white rounded-[26px] h-[45px]  mb-[25px] `}
          >
            Check Your Option
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FinancingCheckOption;
