import { Image } from "antd";
import React from "react";

function ServicesInfoTwo({ second }) {
  console.log("second ", second);
  return (
    <div className="flex justify-center gap-x-[30px] flex-row-reverse gap-y-[30px] tablet_LG:flex-col   items-center mb-[40px] px-[80px] tablet:px-[30px] w-full">
      <div className="flex justify-start items-start flex-col w-full   max-w-[700px] ">
        <div className="flex justify-start items-start text-[24px]  font-[700]   mb-[30px] w-full">
          {second?.title}
        </div>
        <div className="flex justify-start items-start text-[16px] font-[600]  w-full mb-[30px] ">
          {second?.info}
        </div>
      </div>
      <div className="flex justify-center h-[400px]  items-center w-full ">
        <Image
          src={second?.url}
          alt="img"
          preview={false}
          width="100%"
          height="100%"
          className="object-cover"
        />
      </div>
    </div>
  );
}

export default ServicesInfoTwo;
