import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

function FreeQuoteToday() {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center flex-col items-center w-full py-[50px] px-[20px] ">
      <div className="flex justify-center items-center text-center text-[42px] mobile:text-[30px] font-[700] ">
        Get a Free Quote Today
      </div>
      <div className="flex justify-center items-center text-center text-[18px] font-[300] w-full max-w-[700px] my-[50px]">
        Get the help you need for your project from a qualified professional.
      </div>
      <div className="flex justify-center items-center gap-x-[20px] tablet:flex-col w-full max-w-[650px] tablet:max-w-[400px] ">
        <div className="flex justify-center items-center w-full">
          <Button
            onClick={() => navigate("/contact")}
            className={`felx items-center justify-center text-[18px] bg-[#AAE127] border-[#AAE127] w-full  hover:border-1-solid hover:!border-[#AAE127]  rounded-[26px] h-[45px]  hover:!text-black mb-[25px] font-[700] `}
          >
            Schedule Your Free Inspection
          </Button>
        </div>
        <div className="flex justify-center items-center w-full">
          <Button
            onClick={() => navigate("/contact")}
            className={`felx items-center justify-center text-[18px] bg-[#AAE127] border-[#AAE127] w-full hover:border-1-solid hover:!border-[#AAE127] rounded-[26px] h-[45px] hover:!text-black  mb-[24px] font-[700] `}
          >
            Find Our Nearest Location
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FreeQuoteToday;
