import React, { useEffect } from "react";
import MainLayout from "../component/shared/layout/MainLayout";
import LadyComponent from "../component/shared/LadyComponent/LadyComponent";
import CertifiedComponent from "../component/shared/Certified/CertifiedComponent";
import FreeQuoteToday from "../component/shared/FreeQuote/FreeQuoteToday";
import SafeBet from "../component/SafeBet/SafeBet";
import FinancingCheckOption from "../component/FinacingCheckOption/FinancingCheckOption";
import PageTopComponent from "../component/shared/PageTopComponet/PageTopComponent";
import { financing } from "../Services/Financing";
import { useLocation } from "react-router-dom";

function Financing() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <MainLayout>
        <PageTopComponent componentdata={financing} />
        <SafeBet />
        <FinancingCheckOption />
        <LadyComponent />
        <CertifiedComponent />
        <FreeQuoteToday />
      </MainLayout>
    </div>
  );
}

export default Financing;
