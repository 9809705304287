export const service_Blown = {
  title: "Weatherizing ",
  url: "/Images/s-bg-2.png",
  info: "Your home’s thermal envelope includes the attic, crawlspace or basement, and walls and windows.  Air leakage through doors and windows can contribute to high energy bills and is an easy, relatively inexpensive solution, especially in older homes or in new construction.",
}
export const madeFrom = [
  {
    id: 1,
    url: "/Images/made-11.png",
    title: "Spray Foam",
  },
  {
    id: 2,
    url: "/Images/made-12.png",
    title: " Siliconized Acrylic Caulk",
  },
]

export const servies_info = {
  title: "Benefits of Weatherizing your Home",
  url: "/Images/sw-1.png",
  info: "If you’re feeling drafts, especially near windows and doors, weatherizing may be in order to make your home more energy efficient and lower your energy bills in the process.  Whether you have an older home or are working on a new build, weatherizing your doors and windows is an easy way to lower air leakage in your home.Most DIY-inclined people have installed their own weather strips on doors and windows, but what lurks behind your window and door trim?  Upon inspection with a thermal camera, the areas around doors and windows are on the top of the list for air leakage, right behind where your drywall meets your top plate.Removing window and door trim to seal with caulk and in the case of larger gaps, spray foam insulation, can reduce air leakage and increase your home’s comfort. Here’s the secret:  many companies advertise and install energy efficient windows for an extreme cost, but the actual R-Value difference is very small.  Many customers feel a great difference in comfort, however, but it is due to the installer’s air sealing around the installed windows rather than the windows themselves. ",
}
export const second_info = {
  title: "Why Weatherize?",
  url: "/Images/sw-2.png",
  info: "Many weatherization steps can be taken as DIY-projects.  Installing weather-stripping around doors and windows is simple and easy, requires little labor or time and no demolition or replacement.Professional weatherization can add to your DIY projects by going the extra mile.  Our experts use thermal cameras to detect air leakage, will remove and replace window trim, adding caulk or spray foam to fill any air gaps in a way that is quantifiable with a camera or blower door test.Oftentimes, during any home insulation project, weatherization of doors and windows can be added on at little cost and provides great protection from pests and the elements.  Contact us today for a free inspection and quote for complete weatherization in conjunction with any insulation or ventilation project. ",
}

export const values = [
  {
    id: 1,
    URL: "/Images/close-circle-line.png",
    title: "Prevent Air Infiltration",
    info: "Reduce the amount of outside air bringing in pollen and other allergens into your home.",
  },
  {
    id: 2,
    URL: "/Images/history-line.png",
    title: "Long-Lasting Benefits",
    info: "Weatherization provides long-term benefits that can last for the lifetime of your windows and doors.",
  },
  {
    id: 3,
    URL: "/Images/windy-line.png",
    title: "Increase HVAC Lifespan",
    info: "Reducing air loss or air exchange reduces strain on your HVAC unit, leading to a longer lifespan.",
  },
  {
    id: 4,
    URL: "/Images/heart-3-line.png",
    title: "Whole Home Comfort",
    info: "Less drafts from windows and doors leads to a more uniform temperature in your home.",
  },
  {
    id: 5,
    URL: "/Images/icon1.png",
    title: "Cost Effective",
    info: "Low cost way to increase efficiency of HVAC and lower energy bills.",
  },
  {
    id: 6,
    URL: "/Images/icon2.png",
    title: "Energy Efficient",
    info: "Any form of air sealing contributes to higher energy efficiency.",
  },
]

export const title = "Benefits of Weatherization"
