export const service_Blown = {
  title: "Blown In Insulation",
  url: "/Images/s-bg-2.png",
  info: "Ideal for hard-to-reach areas in your home, our blown in insulation services utilize high-quality cellulose or fiberglass. This method provides excellent thermal resistance, reducing energy waste and improving the comfort and efficiency of your home.",
}
export const madeFromBlown = [
  {
    id: 1,
    url: "/Images/fmade-1.png",
    title: "Fiberglass",
  },
  {
    id: 2,
    url: "/Images/fmade-2.png",
    title: "Mineral Wool",
  },
]

export const servies_info = {
  title: "The Benefits of Blown-In Insulation",
  url: "/Images/sb-1.png",
  info: `Blown-In Insulation is the most commonly used insulation in attics, typically made of fiberglass or recycled cellulose fibers. Its major advantage is its flexibility in achieving any desired R-Value for your attic, allowing a customized solution for your home's unique insulation needs. Both types of insulation offer fire resistance, and modern blown-in insulation is engineered to resist settling, ensuring long-lasting comfortThis insulation option is not only affordable but also ${(
    <br />
  )}highly effective, making it an ideal choice for attic insulation. It can be easily added on top of existing insulation in your attic, or installed inside walls, making it perfect for retrofitting older homes that lack adequate insulation. ${(
    <br />
  )}Discover the benefits of blown-in insulation by reaching out to us. Our experts can help you determine the right type for your home's insulation requirements, ensuring a comfortable and energy-efficient living environment..`,
}
export const second_info = {
  title: "The Importance of Air Sealing and Ventilation",
  url: "/Images/sb-2.png",
  info: `When it comes to installing Blown-In insulation in your attic, proper ventilation and air sealing are crucial factors to consider. Maintaining or creating adequate ventilation through your roof and soffits helps prevent moisture buildup and potential mold issues while ensuring the insulation's efficiency.${(
    <br />
  )}
  
    Air leaks between your attic and living spaces can be a real energy-waster. Contaminated pathways like recessed lighting, air ducts, and other utilities can allow your precious heated or cooled indoor air to escape into the attic, leading to higher energy bills, reduced home comfort, and unnecessary strain on your HVAC unit.
    ${(<br />)}
    
    Our team of experts is well-equipped to assess your attic's ventilation and identify air leaks, tailoring solutions to your home's specific needs. By optimizing your attic's efficiency, we can help you enjoy a more comfortable, energy-efficient living environment.
    ${(<br />)}
    Contact us for more information, or explore the significance of air sealing and ventilation in maintaining a well-insulated home here.`,
}

export const values = [
  {
    id: 1,
    URL: "/Images/chat-check-fill.png",
    title: "Quick Installation",
    info: "Installation is quicker than most types of insulation.",
  },
  {
    id: 2,
    URL: "/Images/history-line.png",
    title: "Long-Lasting Insulation",
    info: "Can maintain effectiveness for decades.",
  },
  {
    id: 3,
    URL: "/Images/icon1.png",
    title: "Highly Cost Effecient",
    info: "Costs less due to quick and easy installation.",
  },
  {
    id: 4,
    URL: "/Images/icon6.png",
    title: "Versatile",
    info: " Installation is quicker than most types of insulation.",
  },
  {
    id: 5,
    URL: "/Images/fire-line.png",
    title: "Naturally FIre-Resistant",
    info: "Makes your home more resistant to fire.",
  },
  {
    id: 6,
    URL: "/Images/sb-icon-1.png",
    title: "Eco-Friendly",
    info: "Can Be made from recycled materials.",
  },
]

export const titleblown = "Benefits of Blown-In Insulation"
