import { Image } from "antd";
import React from "react";

function AdvantageSafeGuard() {
  return (
    <div className="flex justify-center items-center w-full h-full bg-[#1869A9] py-[30px] gap-x-[30px] gap-y-[30px] tablet:flex-col px-[30px]">
      <div className="flex justify-center items-center w-[51px] h-[51px] img">
        <Image
          src="/Images/logo-footer.png"
          alt="img"
          preview={false}
          className="object-cover"
        />
      </div>
      <div className="flex justify-center items-center text-white text-[33px]   font-[600] text-center">
        Experience the SafeGuard Advantage
      </div>
    </div>
  );
}

export default AdvantageSafeGuard;
