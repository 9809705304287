import { Image, Rate } from "antd";
import React from "react";
import { ShareAltOutlined } from "@ant-design/icons";

const reviews = [
  {
    id: 1,
    name: " Linda jo T. Columbia, MO",
    info: "Chase and Alexa were professional and always on time. They communicated effectively and kept us fully informed. We particularly liked the photos and thorough discussion of any problem areas. Excellent!",
  },
  {
    id: 2,
    name: "Christina L. Stover, MO",
    info: "Chase was very responsive and knowledgeable, will definitely use them again.",
  },
  {
    id: 3,
    name: "Rick T. Boonville, Missouri, MO",
    info: "Chase and his partner were both very professional, courteous and completed the job on time. We wish them both all the best!",
  },
  {
    id: 4,
    name: "Jennifer B. Boonville, MO",
    info: "They were on time as scheduled, and did a great job . Price was fair and I have regrets hiring them.",
  },
  {
    id: 5,
    name: " Frank B. Waynesville, MO",
    info: "Hire them, we will again!!",
  },
  {
    id: 6,
    name: "Mary W. Lake Ozark, MO",
    info: "Received information about our Radon testing device that made us feel more comfortable about it working properly. We were treated with respect.",
  },
  {
    id: 7,
    name: " Keith B. Lake Ozark, MO",
    info: "First Chase was so quick to answer my online inquiry. Literally within minutes I had a quote for service (on a Sunday!) and the service scheduled in one more day! When does that ever happen? Chase and Alexa were very knowledgeable and willing to go the extra mile.",
  },

  {
    id: 8,
    name: "Cara B. Columbia, MO",
    info: "They came out as they said. They made sure the work was of quality and reassured me to call if I had any problems. They were professional and cordial. Would very much recommend them",
  },
  {
    id: 9,
    name: "Cynthia B. Dixon, MO",
    info: "Chase and Lexa were a delight to work with. They jumped right on my message when I placed a request with Anji. They were out to our home at their and our earliest convenience. ",
  },
  {
    id: 10,
    name: "Jackie S. Stover, MO",
    info: "On the initial call Chase answered all my questions and provided a clear description of the work to be performed along with the code requirements.",
  },
  {
    id: 11,
    name: "Jennifer F. Fulton, MO",
    info: "Chase was professional. Explained every step of the process of sealing my basement crack. Very reasonable in price and quick",
  },
  {
    id: 12,
    name: " Garry Y. Warsaw, MO",
    info: "Chase was great to work with and met challenging deadlines to support our closing!",
  },
  {
    id: 13,
    name: "Tim H. Sunrise Beach, MO",
    info: "Very prompt and professional!",
  },
  {
    id: 14,
    name: " Bridget C. Columbia, MO",
    info: "These guys for a small business but super professional and really good at what they do they know what theyâ re talking about her...",
  },
];

function ReviewsComponent() {
  return (
    <div className="flex justify-center items-center gap-[14px] my-[10%] px-[30px] flex-wrap w-full  ">
      {reviews.map((item, index) => {
        return (
          <div
            key={index}
            className="flex justify-start flex-col items-start px-[22px] py-[24px] border-2 border-solid border-[#D9D9D9D9] w-full max-w-[400px]  h-[400px] rounded-[24px] "
          >
            <div className="flex justify-center items-center gap-x-[20px]">
              <div className="flex justify-center items-center w-[60px] h-[60px] rounded-full  bg-[#D9D9D9D9]"></div>
              <div className="flex justify-start items-start flex-col ">
                <div className="flex justify-start items-start text-[18px] font-[700]">
                  {item.name}
                </div>
                <div className="flex justify-start items-start text-[#0000008f] text-[18px] font-[400] ">
                  a day ago
                </div>
              </div>
            </div>
            <div className="flex justify-start items-center gap-x-[20px] mt-[20px] ">
              <div className="felx justify-center items-center text-[18px]">
                <Rate disabled defaultValue={5} />
              </div>
              <div className="flex justify-center items-center w-[25px] h-[25px] img">
                <Image
                  src="/Images/angi.png"
                  alt="google-icon"
                  preview={false}
                  width="100%"
                  height="100%"
                  className="object-cover"
                />
              </div>
            </div>
            <div className="flex justify-start items-start text-[18px] font-[400]  my-[20px] ">
              {item.info}
            </div>
            <div className="flex justify-center items-center text-[25px] text-[#00000077]">
              <ShareAltOutlined />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ReviewsComponent;
