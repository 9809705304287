export const Review = {
  page: "Reviews",
  title: "HEAR WHAT PEOPLE HAVE TO SAY ABOUT SAFEGUARD",
  info: "",
  imageUrl: "/Images/R-bg.png",
  background: "reviews",
  backgroundColor: "#ADCB41",
  bgColorOpacity: "#adcb4194",
  buttonColor: "#AAE127",
  button: true,
}
