export const Resource = {
  page: "Free Resources",
  title: "Helpful Information for your home",
  info: "",
  imageUrl: "/Images/free-r.png",
  background: "resources",
  backgroundColor: "#3DB0E1",
  bgColorOpacity: "#1b76bc83",
  buttonColor: "",
  button: false,
}
