import axios from "axios";

export const getBlogs = async () => {
  return await axios
    .get(`https://safeguard-5ad5b44a2a70.herokuapp.com/api/blog`)
    .then((response) => response.data);
};

export const getBlogById = async ({ id }) => {
  return await axios
    .get(`https://safeguard-5ad5b44a2a70.herokuapp.com/api/blog/${id}`)
    .then((response) => response.data);
};
