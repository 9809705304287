import { Image } from "antd";
import React from "react";
import FindLocationInput from "../findLocationInput/FindLocationInput";

function LadyComponent() {
  return (
    <div className="flex justify-center items-end  w-full  h-full ">
      <div className="flex justify-start items-start  w-full h-full  border-4 border-solid border-white max-h-[800px] lady_Component img pt-[8px] tablet_LG:hidden pl-[8px]">
        <div className="flex justify-center items-start border-r-4 border-solid border-white bg-[#27a9e18c] flex-col  w-full h-full max-h-[600px]  max-w-[650px]  py-[20px] px-[40px] ">
          <div className="felx justify-start items-start w-[143px] h-[143px] mt-[30px] ">
            <Image
              src="/Images/logo-footer.png"
              alt="img"
              preview={false}
              className="object-cover"
            />
          </div>
          <div
            className="flex justify-start items-start text-[32px] text-white  font-[700] w-full 
          "
          >
            Hear From A Happy Customer in Mid-Missouri
          </div>
          <div className="flex justify-center items-start text-white text-[18px] font-[400]">
            They came out as they said. They made sure the work was of quality
            and reassured me to call if I had any problems. They were
            professional and cordial. Would very much recommend them. - Cynthia
            B. from Dixon, Missouri
          </div>
          <div className="flex justify-start items-start">
            <FindLocationInput />
          </div>
        </div>
      </div>
      <div className="hidden tablet_LG:block w-full">
        <div className="flex justify-center items-center w-full mobile:border-r-8 mobile:border-solid mobile:border-white min-w-full  h-[500px]  lady_Component_m mb-[10px] img">
          {/* <Image
            src="/Images/lady-m.png"
            alt="image-house"
            width={"100%"}
            height={"100%"}
            preview={false}
          /> */}
        </div>
        <div className="flex justify-center items-center text-center border-4 border-solid border-white bg-[#27a9e1d7] flex-col  w-full   max-w-full gap-y-[20px] py-[50px] px-[40px] ">
          <div className="felx justify-start items-start w-[143px] h-[143px] mt-[30px] ">
            <Image src="/Images/logo-footer.png" alt="img" preview={false} />
          </div>
          <div
            className="flex justify-start items-start text-[32px] text-white  font-[700] w-full tablet_LG:text-center 
          "
          >
            Hear From A Happy Customer in Mid-Missouri
          </div>
          <div className="flex justify-center items-start text-white text-[18px] font-[400] tablet_LG:text-center ">
            They came out as they said. They made sure the work was of quality
            and reassured me to call if I had any problems. They were
            professional and cordial. Would very much recommend them. - Cynthia
            B. from Dixon, Missouri
          </div>
          <div className="flex justify-start items-start">
            <FindLocationInput />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LadyComponent;
