export const service_spray = {
  title: "Energy Audits",
  url: "/Images/s-bg-2.png",
  info: "Have you ever wondered how your home fares in energy efficiency?  Maybe you have experienced high energy bills and are wondering where your money is disappearing to.  Our home energy audits can quantify how efficient your home is as well as discover problem areas that need to be addressed.",
}
export const madeFrom = [
  {
    id: 1,
    url: "/Images/made-13.png",
    title: "Thermal Camera",
  },
  {
    id: 2,
    url: "/Images/made-14.png",
    title: "Blower Door Testing",
  },
]

export const servies_info = {
  title: "What is an Energy Audit?",
  url: "/Images/se-1.png",
  info: "Usually the word “Audit” is accompanied by the very scary word, “taxes”.  In this case, a home energy audit simply exists to quantify your home’s energy efficiency and discover air leakage or problem areas that need to be addressed.  Whether you are looking for tax credits or searching for an answer to your home’s high energy bills, an energy audit can provide valuable information on how to maximize any benefits from a home insulation and air sealing project.As our world moves further and further towards energy efficient living, and our energy bills continue to climb, finding the biggest issues and addressing them can save you money on bills and sometimes provide tax credits or government rebates.  in order to receive these incentives, a quantifiable test for energy efficiency must be executed.Our team uses the most up to date technology, including thermal cameras and blower doors, to discover air leaks and quantify the air exchange rate in your home.  To learn more about blower door tests, please read our article here or contact us for more details.",
}
export const second_info = {
  title: "Benefits of Home Energy Audit",
  url: "/Images/se-2.png",
  info: "Having a home energy audit can quantify the value of any insulation project completed on your home.  While feeling the difference is everything, being able to see on paper the difference in air exchange or see thermal gaps filled gives piece of mind and can add to your home’s value if you ever decide to sell your home.We encourage homeowners to look into current and future tax and rebate incentives offered for insulation as we move towards a greener, more environmentally-friendly future.  Getting your older home up to date can lead to tax breaks, government rebates, and increased home value.  With energy costs rising higher than ever, knowing where and how to seal and insulate your home can save you hundreds over the course of a year alone in energy bills.",
}

export const values = [
  {
    id: 1,
    URL: "/Images/icon5.png",
    title: "Quantify Loss and Improvement",
    info: "Be armed with numbers, photos, and data on the before and after of your home improvement project.",
  },
  {
    id: 2,
    URL: "/Images/home-2-line.png",
    title: "Increased Home Value",
    info: "Increase your home’s value as the country and building codes move towards further energy efficiency.",
  },
  {
    id: 3,
    URL: "/Images/icon1.png",
    title: "Save on Energy Bills",
    info: "Finding problem areas and finding targeted solutions can maximize energy savings for less cost.",
  },
]

export const title = "Benefits of Home Energy Auditing"
