export const service_spray = {
  title: "Mold Removal and Remediation",
  url: "/Images/s-bg-2.png",
  info: "Mold is a difficult and complex problem, whether in your crawlspace or attic.  Often, ventilation or lack thereof plays a part in the development of mold and mildew on the wood in your home.  Taking care of the issue before it’s too late, and addressing the origin of the issue will protect your home’s structural integrity and indoor air quality.",
}
export const madeFrom = [
  {
    id: 1,
    url: "/Images/made-15.png",
    title: "Fungicide",
  },
  {
    id: 2,
    url: "/Images/made-16.png",
    title: "HEPA Vacuum",
  },
]

export const servies_info = {
  title: "The Perfect Formula for Mold Growth",
  url: "/Images/sbr-1.png",
  info: "Mold thrives in a hot, humid environment.  Unfortunately in the Midwest, this is a common occurrence.  Attics and crawlspaces can become a haven for mold growth if the source of water or moisture is not brought under control.In attics, ventilation is the most important factor to prevent mold growth.  Installing baffles, ensuring properly ventilated soffits and roof vents will provide adequate ventilation to deter the growth of mold.  In the case of a spray-foamed attic,  a dehumidifier may be needed to lower the humidity.In a crawl space in the Midwest, standard building practices may even be working against your home. Vents installed in a crawlspace in a humid environment can often make things worse.  Heat and humidity coming from the outside will not dry out your crawlspace as it does in drier climates.  It is now recommended to encapsulate, dehumidify, and seal off the vents to prevent this infiltration of humidity.",
}
export const second_info = {
  title: "Mold Remediation and Treatment",
  url: "/Images/sbr-2.png",
  info: "If you are experiencing mold or humidity problems in your crawlspace or attic, it is not only important to treat the mold itself but to address the source of mold growth.By using hospital-grade fungicide treatment and disinfectant, we can stop mold and mildew in its tracks.  And while arresting its further growth is important, the best solution is to fix whatever conditions were causing the moldy growth in the first place.After treating for mold, the conditions that contribute to mold must be addressed.hether this involves installing adequate attic ventilation, encapsulating your crawlspace, installing a dehumidifier or a combination of these solutions, our experts are ready to evaluate and recommend a long-term solution to your problem to prevent mold regrowth and create a safe environment for your home.",
}

export const values = [
  {
    id: 1,
    URL: "/Images/close-circle-line.png",
    title: "Stop Mold in its Tracks",
    info: "Mold removal and killing treatment to completely remove and arrest further mold development.",
  },
  {
    id: 2,
    URL: "/Images/history-line.png",
    title: "Long-Lasting Benefits",
    info: "Address the root cause of mold to prevent future growth.",
  },
  {
    id: 3,
    URL: "/Images/icon1.png",
    title: "Prevent Future Repair Costs",
    info: "Preserve your home before mold and moisture destroy your home’s structural integrity.",
  },
  {
    id: 4,
    URL: "/Images/windy-line.png",
    title: "Improved Indoor Air Quality",
    info: " Eliminating mold in attic or crawlspace increases indoor air quality..",
  },
  {
    id: 5,
    URL: "/Images/icon7.png",
    title: "Guaranteed Results",
    info: "When paired with solutions to remediate humidity and regrowth.",
  },
  {
    id: 6,
    URL: "/Images/home-2-line.png",
    title: "Preserve Home Value",
    info: "Mold and structural damage can decrease your home’s resale value.",
  },
]

export const title = "Benefits of Mold Remediation"
