export const aboutpage = {
  page: "About Us",
  title: "Why SafeGuard Insulation?",
  info: "Learn what makes SafeGuard Insulation the top choice for any of your insulation needs.",
  imageUrl: "/Images/about.jpg",
  background: "about",
  backgroundColor: "#AAE127",
  bgColorOpacity: "#a9e12784",
  buttonColor: "#AAE127",
  button: true,
}

export const aboutHousepage = {
  title: "Our History",
  info: "Sometimes, great things can come in small packages. We are a small business, in a small town, but that doesn’t mean we can’t achieve big results. Our family started in construction and home building in 1973, and it is our privilege to carry on that legacy. A big dream led us to start up a business and leverage our experience to begin doing crawl space overhauls, as well as various other projects for homeowners. By caring a lot, and taking accountability and ownership, we felt we could deliver an incredible experience for homeowners.  And we know from experience that it is always best to get things right the first time. It’s our privilege to “always be learning”, to learn and grow from some of the best in the industry.From our family to yours, thank you for giving us a chance to make  difference!",
}
