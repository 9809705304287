import { Button, Form, Image, Input, Select } from "antd";
import React from "react";

function ContactForm() {
  return (
    <div className="flex justify-center items-center w-full  px-[70px] mobile:px-[30px] gap-x-[20px] my-[50px] tablet_LG:flex-col-reverse ">
      <div className="flex justify-start items-start flex-col w-full ">
        <div className="flex justify-start items-start  text-[25px] font-[600] mt-[20px] mb-[20px] secondary-heading">
          Serving Mid-Missouri
        </div>
        <div className="flex justify-center items-center text-[18px] text-[400] mb-[70px]">
          No job is too big or small. Get in touch and let’s explore the
          possibilities for your project.
        </div>
        <div className="flex justify-start items-start text-[25px] font-[600] mb-[50px]">
          Get in touch
        </div>
        <div className="flex justify-start items-start gap-y-[20px] flex-col mb-[30px] ">
          <div className="flex justify-start items-start gap-x-[15px]">
            <div className="flex justify-center items-center img w-[24px] h-[24px] ">
              <Image
                src="/Images/phone2.png"
                alt="icon"
                className="object-cover"
              />
            </div>
            <div className="flex justify-start items-start text-[18px] font-[400]">
              573-877-7377
            </div>
          </div>
          <div className="flex justify-start items-start  gap-x-[15px]">
            <div className="flex justify-center items-center w-[24px] h-[24px] img ">
              <Image
                src="/Images/mail.png"
                alt="icon"
                className="object-cover"
              />
            </div>
            <div className="flex justify-start items-start text-[18px] font-[400]">
              <a href="mailto:contact@safeguardhomesolutions.com">
                contact@safeguardhomesolutions.com
              </a>
            </div>
          </div>
          <div className="flex justify-start items-start  gap-x-[15px] ">
            <div className="flex justify-center items-center w-[24px] h-[24px] img ">
              <Image
                src="/Images/location.png"
                alt="icon"
                className="object-cover"
              />
            </div>
            <div className="flex justify-start items-start text-[18px] font-[400]">
              4655 Osage Beach Pkwy, Ste A, Osage Beach MO 65065
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center tablet_LG:hidden  img  w-full max-w-[700px] h-[400px] ">
          <Image
            src="/Images/Map.png"
            alt="map"
            preview={false}
            width="100%"
            height="100%"
            className="object-cover"
          />
        </div>
      </div>
      <div className="flex justify-center items-center w-full h-full min-h-[500px] tablet_LG:px-[0px] px-[30px] py-[30px] ">
        <Form
          layout="vertical"
          className="flex justify-center items-center w-full flex-col  gap-y-[5px]"
        >
          <Form.Item
            label="Full Name"
            name="username"
            className="w-full font-[500]"
            rules={[{ required: true, message: "Please Enter the full name" }]}
          >
            <Input className="h-[50px]" />
          </Form.Item>
          <Form.Item
            label="Company Name"
            name="company_name"
            className="w-full font-[500]"
          >
            <Input className="h-[50px]" />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phone_number"
            className="w-full font-[500]"
            rules={[
              { required: true, message: "Please Enter the Phone Number" },
            ]}
          >
            <Input type="number" className="h-[50px]" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="company_name"
            className="w-full font-[500]"
            rules={[{ required: true, message: "Please Enter the Email" }]}
          >
            <Input type="email" className="h-[50px]" />
          </Form.Item>
          <Form.Item
            label="What us state are you in?*"
            name="What_us_state"
            className="w-full font-[500] h-[50px] select mb-[40px] "
            rules={[{ required: true, message: "Please Enter the input " }]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
                height: "100%",
              }}
              placeholder="Please select"
              defaultValue={[]}
              options={[
                {
                  label: "option 1",
                  value: "option 1",
                },
                {
                  label: "option 2",
                  value: "option 2",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="How can we help?*"
            name="help"
            className="w-full font-[500] h-[50px] select mb-[40px] "
            rules={[{ required: true, message: "Please Enter the input " }]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
                height: "100%",
              }}
              placeholder="Please select"
              defaultValue={[]}
              options={[
                {
                  label: "option 1",
                  value: "option 1",
                },
                {
                  label: "option 2",
                  value: "option 2",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Anything else we should know?"
            name="anything"
            className="w-full font-[500]"
          >
            <Input className="h-[50px]" />
          </Form.Item>
          <div className="flex justify-start items-start w-full ">
            <Button
              type="primary"
              className={`flex items-center tablet_LG:w-full mt-[30px] justify-center text-[18px] !bg-[#AAE127] border-[#AAE127] text-[white] rounded-[26px] h-[45px]  mb-[25px] `}
            >
              {" "}
              Send Message
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ContactForm;
