import { Image } from "antd";
import React from "react";

function MapComponent() {
  return (
    <div className="flex justify-between tablet_LG:justify-center items-center gap-x-[30px] mobile:gap-x-[0px] gap-y-[50px] tablet_LG:flex-col  my-[10px] w-full ">
      <div className="flex justify-start items-start tablet_LG:justify-center !tablet_LG:items-center flex-col gap-y-[30px]  w-full pl-[80px]     max-w-[600px] tablet_LG:pl-[30px] px-[40px] ">
        <div className="flex justify-center font-semibold items-start tablet_LG:items-end tablet_LG:text-center text-[42px] mobile:text-[30px] ">
          We Serve Mid-Missouri
        </div>
        <div className="flex justify-start items-start  gap-x-[15px] ">
          <div className="flex justify-start items-start mt-[5px]  img">
            <Image
              src="/Images/true.png"
              alt="true-icon"
              width={20}
              height={15}
              preview={false}
              className="object-cover"
            />
          </div>
          <div className="flex justify-start items-start text-start text-[18px] font-[300]">
            If you're located outside of our service area, don't hesitate to get
            in touch – we love meeting people.
          </div>
        </div>
        <div className="flex justify-start items-start gap-x-[15px]">
          <div className="flex justify-start items-start mt-[5px] img">
            <Image
              src="/Images/true.png"
              alt="true-icon"
              preview={false}
              width={20}
              height={15}
              className="object-cover"
            />
          </div>
          <div className="flex justify-start items-start text-start text-[18px] font-[300]">
            Be sure to explore our Free Resources page for valuable tips and
            information.
          </div>
        </div>
        <div className="flex justify-start items-start gap-x-[15px] w-full max-w-[400px] ">
          <div className="flex justify-start items-start mt-[5px] img">
            <Image
              src="/Images/true.png"
              alt="true-icon"
              preview={false}
              width={20}
              className="object-cover"
              height={15}
            />
          </div>
          <div className="flex justify-start items-start text-start text-[18px] font-[300]">
            We'll keep our map updated as we grow, so check back often!
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center w-full max-w-[600px] tablet_LG:min-w-full h-full max-h-[800px] tablet_LG:mt-[50px] img z-0">
        <Image
          src="/Images/Map.png"
          alt="image"
          preview={false}
          width="100%"
          height="100%"
          className="object-cover"
        />
      </div>
    </div>
  );
}

export default MapComponent;
