import { Image } from "antd";
import React from "react";

const flexCenter = "flex justify-center items-center";

function TopBar() {
  return (
    <div className="flex justify-end items-center  w-full h-[40px] opacity-100s  bg-[#1869A9]">
      <div className={`${flexCenter} mobile:hidden  pr-[55px]  gap-x-[21px]`}>
        <div className={`${flexCenter} gap-x-[5px] `}>
          <div className={`${flexCenter} w-[24px] h-[21px] mt-[1px]`}>
            <Image
              src="/Images/mail-line.png"
              alt="mail-line"
              preview={false}
              className="object-cover"
            />
          </div>
          <div
            className={`${flexCenter} tablet_LG:hidden text-[16px] font-[500] leading-normal text-white  `}
          >
            <a href="mailto:contact@safeguardhomesolutions.com">
              contact@safeguardhomesolutions.com
            </a>
          </div>
        </div>
        <div className={`${flexCenter} w-[1px] h-[18px] `}>
          <Image
            src="/Images/line.png"
            alt="line-icon"
            preview={false}
            className="object-cover"
          />
        </div>
        <div className={`${flexCenter} gap-x-[5px] cursor-pointer`}>
          <div className={`${flexCenter} w-[24px] h-[21px] mt-[1px]`}>
            <Image
              src="/Images/phone-line.png"
              alt="phone-line"
              preview={false}
              className="object-cover"
            />
          </div>
          <a
            href="mailto:contact@safeguardhomesolutions.com"
            className={`${flexCenter} tablet_LG:hidden text-[16px] font-[500] leading-normal text-white`}
          >
            (573) 877-7377
          </a>
        </div>

        <div className={`${flexCenter} w-[1px] h-[18px] `}>
          <Image
            src="/Images/line.png"
            alt="line-icon"
            preview={false}
            className="object-cover"
          />{" "}
        </div>
        <div className={`${flexCenter} gap-x-[5px]`}>
          <div className={`${flexCenter} w-[24px] h-[21px] mt-[1px]  `}>
            <Image
              src="/Images/web-icon.png"
              alt="mail-line"
              preview={false}
              className="object-cover"
            />{" "}
          </div>
          <div
            className={`${flexCenter} tablet_LG:hidden text-[16px] font-[500] leading-normal text-white  `}
          >
            Missouri, USA
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
