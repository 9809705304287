import React, { useEffect } from "react";
import MainLayout from "../component/shared/layout/MainLayout";
import LadyComponent from "../component/shared/LadyComponent/LadyComponent";
import CertifiedComponent from "../component/shared/Certified/CertifiedComponent";
import FreeQuoteToday from "../component/shared/FreeQuote/FreeQuoteToday";
import PageTopComponent from "../component/shared/PageTopComponet/PageTopComponent";
import ServicesProvides from "../component/shared/ServicesProvides/ServicesProvides";
import { servicepage, services } from "../Services/Services";
import { useLocation } from "react-router-dom";

function Services() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <MainLayout>
        <PageTopComponent componentdata={servicepage} />
        <ServicesProvides servicecomponent={services} />
        <LadyComponent />
        <CertifiedComponent />
        <FreeQuoteToday />
      </MainLayout>
    </div>
  );
}

export default Services;
