import { Image } from "antd";
import React from "react";

function ServicesInfo({ first }) {
  console.log("first log", first);
  return (
    <div className="flex justify-center gap-x-[30px] gap-y-[30px] my-[70px] tablet_LG:flex-col items-center mb-[40px] px-[80px] tablet:px-[30px] w-full">
      <div className="flex justify-start items-start flex-col w-full   max-w-[700px] ">
        <div className="flex justify-start items-start text-[24px]  font-[700]   mb-[30px] w-full">
          {first?.title}
        </div>
        <div className="flex justify-start items-start text-[16px] font-[600]  w-full mb-[30px] ">
          {first?.info}
        </div>
      </div>
      <div className="flex justify-center h-[400px]  items-center w-full ">
        <Image
          src={first?.url}
          alt="img"
          preview={false}
          width="100%"
          height="100%"
          className="object-cover"
        />
      </div>
    </div>
  );
}

export default ServicesInfo;
