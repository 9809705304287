import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";

import HomePage from "./pages/HomePage";
import Reviews from "./pages/Reviews";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import ServicesDetail from "./pages/ServicesDetail";
import ServicesFiberGlass from "./pages/ServicesFiberGlass";
import ServicesBlown from "./pages/ServicesBlown";
import ServicesSprayFoam from "./pages/ServicesSprayFoam";
import ServicesCrwonSpace from "./pages/ServicesCrwonSpace";
import ServesFoundation from "./pages/ServesFoundation";
import ServicesSolarFan from "./pages/ServicesSolarFan";
import ServicesAtticRemoval from "./pages/ServicesAtticRemoval";
import FreeResources from "./pages/FreeResources";
import BlogDetail from "./pages/blogdetail";
import Financing from "./pages/Financing";
import ServicesEnaergy from "./pages/ServicesEnaergy";
import ServicesWetherizing from "./pages/ServicesWetherizing";
import ServicesInRemovalInsulation from "./pages/ServicesInRemovalInsulation";
import BlowerDoorTesting from "./pages/BlowerDoorTesting";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/free-resoures" element={<FreeResources />} />
          <Route path="/financing" element={<Financing />} />
          <Route path="/blog-detail/:id" element={<BlogDetail />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services-detail" element={<ServicesDetail />} />
          <Route
            path="/services-fiberglass-batt-insulation"
            element={<ServicesFiberGlass />}
          />
          <Route
            path="/services-blown-insulation"
            element={<ServicesBlown />}
          />
          <Route
            path="/services-attic-fan-wetherizing"
            element={<ServicesWetherizing />}
          />
          <Route
            path="/services-blown-removal-insulation"
            element={<ServicesInRemovalInsulation />}
          />
          <Route
            path="/services-sparyFoam-insulation"
            element={<ServicesSprayFoam />}
          />
          <Route
            path="/services-crawlspace-encapsulation"
            element={<ServicesCrwonSpace />}
          />
          <Route path="/services-energy-audits" element={<ServicesEnaergy />} />
          <Route
            path="/services-foundation-craks-repair"
            element={<ServesFoundation />}
          />
          <Route path="/services-attic-fan" element={<ServicesSolarFan />} />
          <Route
            path="/services-attic-insulation-removal"
            element={<ServicesAtticRemoval />}
          />
          <Route
            path="/servies-blower-door-testing"
            element={<BlowerDoorTesting />}
          />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
