import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServiceCard from "../shared/ServiceCard/ServiceCard";

function ReactSlider({ servicecomponent }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    // <div className="flex justify-center items-center w-full my-[50px] mt-[30px] px-[50px]">
    <Slider
      {...settings}
      className="w-full max-w-[1050px] mobile:!max-w-[340px] h-[400px] flex justify-center items-center mt-[30px]"
    >
      {servicecomponent?.map((item, index) => {
        return <ServiceCard item={item} key={index} />;
      })}
    </Slider>
    // </div>
  );
}

export default ReactSlider;
