import { Image } from "antd"
import React from "react"

function HouseHomeComponent({ houseData }) {
  return (
    <div className="flex justify-center items-end  w-full h-full ">
      <div className="flex justify-end items-end  w-full h-full   border-4 border-solid border-white max-h-[800px] house_img img pt-[8px] tablet_LG:hidden pb-[9px]">
        <div className="flex justify-center items-start border-l-4 border-solid border-white bg-[#27a9e18c] flex-col  w-full h-full  min-h-[500px] max-w-[700px] gap-y-[40px]   px-[40px] ">
          <div
            className="flex justify-start items-start text-[28px] text-white  font-[700] w-full  h-full
      "
          >
            {houseData?.title}
          </div>
          <div className="flex justify-center items-start text-white text-[18px] font-[400] h-full">
            {houseData.info}
          </div>
        </div>
      </div>
      <div className="hidden tablet_LG:block w-full">
        <div className="flex justify-center items-center w-full min-w-full h-full max-h-[700px] img">
          <Image
            src="/Images/house-2.png"
            alt="image-house"
            width="100%"
            preview={false}
            className="object-cover"
          />
        </div>
        <div className="flex justify-center items-center border-4 border-solid border-white bg-[#27a9e1d7] flex-col  w-full h-full  max-w-full gap-y-[20px] py-[50px] px-[40px] ">
          <div
            className="flex justify-center items-center text-center text-[28px] text-white  font-[700] w-full 
      "
          >
            {houseData?.title}
          </div>
          <div className="flex justify-center items-center text-center text-white text-[18px] font-[400]">
            {houseData?.info}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HouseHomeComponent
