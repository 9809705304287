import React, { useEffect } from "react";
import MainLayout from "../component/shared/layout/MainLayout";
import ServicesPageTopComponent from "../component/shared/ServicesPageTopComponent/ServicesPageTopComponent";
import MadeFrom from "../component/shared/MadeFrom/MadeFrom";
import LadyComponent from "../component/shared/LadyComponent/LadyComponent";
import CertifiedComponent from "../component/shared/Certified/CertifiedComponent";
import FreeQuoteToday from "../component/shared/FreeQuote/FreeQuoteToday";
import ServicesInfo from "../component/shared/ServicesInfo/ServicesInfo";
import ServicesInfoTwo from "../component/shared/ServicesInfoTwo/ServicesInfoTwo";
import PageTitle from "../component/shared/PageTitle/PageTitle";
import SharedOurValueComponent from "../component/shared/SharedOurValueComponent/SharedOurValueComponent";
import ButtonContact from "../component/shared/ButtonContact/ButtonContact";
import {
  madeFrom,
  second_info,
  service_Blown,
  servies_info,
  title,
  values,
} from "../Services/ServicesFoundation";
import { headerinfoCrown } from "../Services/ServicesCrown";
import { useLocation } from "react-router-dom";

function ServesFoundation() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <MainLayout>
        <ServicesPageTopComponent pageData={service_Blown} />
        <ServicesInfo first={servies_info} />
        <ServicesInfoTwo second={second_info} />
        <PageTitle title={title} />
        <SharedOurValueComponent values={values} />
        <ButtonContact />
        <MadeFrom madefrom={madeFrom} title={headerinfoCrown} />
        <LadyComponent />
        <CertifiedComponent />
        <FreeQuoteToday />
      </MainLayout>
    </div>
  );
}

export default ServesFoundation;
