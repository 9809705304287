import React, { useEffect } from "react";
import MainLayout from "../component/shared/layout/MainLayout";
import LadyComponent from "../component/shared/LadyComponent/LadyComponent";
import CertifiedComponent from "../component/shared/Certified/CertifiedComponent";
import FreeQuoteToday from "../component/shared/FreeQuote/FreeQuoteToday";
import BlogDetailComponent from "../component/BlogDetailComponent/BlogDetailComponent";
import { useLocation } from "react-router-dom";

function BlogDetail() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <MainLayout>
        <BlogDetailComponent />
        <LadyComponent />
        <CertifiedComponent />
        <FreeQuoteToday />
      </MainLayout>
    </div>
  );
}

export default BlogDetail;
