import React from "react";
import FindLocationInput from "../shared/findLocationInput/FindLocationInput";

function YourInsulation() {
  return (
    <div className="flex justify-start items-start py-[150px] h-full min-h-[500px] home-bg tablet:bg-[#b4997d9a] z-10 mobile:!pt-[75px]">
      <div className="flex justify-center flex-col  items-start w-full px-[40px]  mt-[100px]   z-20 tablet:justify-center tablet:items-center tablet:text-center ">
        <div className="flex justify-start items-start  flex-col w-full max-w-[500px] tablet:justify-center tablet:items-center tablet:my-[40px]  ">
          <div className="flex justify-start items-center text-[35px] font-[700] leading-normal text-white  p-[10px] bg-[#186aa9cf]  -top-16 z-20 relative mobile:text-[28px] ">
            Your Insulation{" "}
          </div>
          <div className="flex justify-start  items-center text-[35px] font-[700] leading-normal text-white  p-[10px] bg-[#a9e127a8]  ml-[15px] tablet:ml-[0px] z-0 absolute mobile:text-[28px] ">
            Ally In Every Season.
          </div>
        </div>
        <div className="flex justify-start flex-col items-center mt-[30px] w-full pl-[15px] max-w-[500px] mobile:max-w-full ">
          <div className="flex justify-start items-center text-white text-[32px] font-[700] mobile:text-[26px]  ">
            Partner With Us For A Comfortable, Well-Protected Home
          </div>
          <div className="flex justify-start items-center text-white text-[18px]  mobile:text-[16px]  ">
            At SafeGuard Home Solutions, we use the best materials and provide
            top-notch service. We do more than just insulate – we commit to
            keeping your home comfortable and safe.
          </div>
        </div>

        <div className="flex justify-start items-start w-full max-w-[400px]  ">
          <FindLocationInput />
        </div>
      </div>
    </div>
  );
}

export default YourInsulation;
