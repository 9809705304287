import React, { useEffect } from "react";
import MainLayout from "../component/shared/layout/MainLayout";
import ServicesPageTopComponent from "../component/shared/ServicesPageTopComponent/ServicesPageTopComponent";
import LadyComponent from "../component/shared/LadyComponent/LadyComponent";
import CertifiedComponent from "../component/shared/Certified/CertifiedComponent";
import FreeQuoteToday from "../component/shared/FreeQuote/FreeQuoteToday";
import ServicesInfo from "../component/shared/ServicesInfo/ServicesInfo";
import ServicesInfoTwo from "../component/shared/ServicesInfoTwo/ServicesInfoTwo";
import PageTitle from "../component/shared/PageTitle/PageTitle";
import SharedOurValueComponent from "../component/shared/SharedOurValueComponent/SharedOurValueComponent";
import ButtonContact from "../component/shared/ButtonContact/ButtonContact";
import { useLocation } from "react-router-dom";

const service_spray = {
  title: "Insulation Removal",
  url: "/Images/s-bg-2.png",
  info: "Whether your attic has experienced pest problems in the past or present, or you own an older home with an outdated and degraded insulation type, you may be an excellent candidate for insulation removal.  Animal excrement and outdated, questionable chemicals are never a good thing to have lurking over your head.  Starting fresh can give you relief from allergies, pesticides, and other contaminates as well as provide a fresh canvas for air sealing your attic.",
};

const servies_info = {
  title: "Why would I need insulation removal?",
  url: "/Images/sr-1.png",
  info: "Insulation removal involves vacuuming any old, existing insulation out to provide a fresh canvas for your installer to air seal every air gap in your attic without the extra labor and risk of missing areas that comes with working around existing insulation.  But that’s not the only benefit in doing a spring cleaning of your attic space.Attics commonly attract pests of all sorts.  Rodents, insects, bats, and even raccoons can sometimes take solace in your attic space if they have the opportunity.  They leave behind excrement, disturb your existing insulation, can destroy baffles and generally leave your attic in disarray.  If your attic has not been air sealed, these contaminants can make their way into your indoor air.Very old homes can also be outfitted with outdated, settled, or damaged insulation that isn’t performing to its best ability.  Getting a clean slate can help improve your attic’s efficiency to a degree that just adding additional insulation cannot.",
};
const second_info = {
  title: "Crawlspace Insulation Removal",
  url: "/Images/sr-2.png",
  info: "If you have a crawlspace under your home, it’s very common for batt insulation to be installed between the floor joists.  It is advertised to help keep your floors insulated and comfortable, but unfortunately has many drawbacks.Over time, this insulation becomes the perfect home for pests.  Even crawlspaces that are regularly treated with pesticides see mice and other rodents making a home inside your insulation, tearing it down, ruining its r-value, and creating a contaminated environment.  50% of your indoor air comes from your crawlspace, so any pests or pesticides used are making their way into your living space.Instead of replacing your insulation every 2-5 years or living with a dilapidated crawlspace, removing this insulation and opting for a crawlspace encapsulation which insulates the walls with a pest-resistant barrier provides a long-lasting and sanitary improvement to your living space.",
};

const values = [
  {
    id: 1,
    URL: "/Images/close-circle-line.png",
    title: "Remove Contaminants",
    info: "Removes animal excrement, allergens, and outdated or damaged insulation.",
  },
  {
    id: 2,
    URL: "/Images/history-line.png",
    title: "Long-Lasting Benefits",
    info: "Installing new materials that are designed to last longer increases attic efficiency and protects lifetime R-Value",
  },
  {
    id: 3,
    URL: "/Images/windy-line.png",
    title: "Improves Indoor Air Quality",
    info: "Removing contaminated insulation improves air quality in your home, and provides an opportunity for air sealing.",
  },
  {
    id: 4,
    URL: "/Images/icon7.png",
    title: "Complete Air Sealing",
    info: " Creates a blank canvas for air sealing your attic or crawlspace.",
  },
  {
    id: 5,
    URL: "/Images/icon1.png",
    title: "Save on Energy Bills",
    info: "Ineffective insulation provides little to no benefit. Starting over can improve efficiency.",
  },
  {
    id: 6,
    URL: "/Images/home-2-line.png",
    title: "Increase Home Value",
    info: "New Insulation increases your home’s resale value.",
  },
];

const title = "Benefits of Insulation Removal";

function ServicesAtticRemoval() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <MainLayout>
        <ServicesPageTopComponent pageData={service_spray} />
        <ServicesInfo first={servies_info} />
        <ServicesInfoTwo second={second_info} />
        <PageTitle title={title} />
        <SharedOurValueComponent values={values} />
        <ButtonContact />

        <LadyComponent />
        <CertifiedComponent />
        <FreeQuoteToday />
      </MainLayout>
    </div>
  );
}

export default ServicesAtticRemoval;
