import React from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import { CloseOutlined, PhoneOutlined } from "@ant-design/icons";

function ModalForm({ openModal, setmodal }) {
  const [form] = Form.useForm();

  const modalCancel = () => {
    setmodal(false);
  };

  const onFinish = (value) => {
    console.log(value);
  };

  return (
    <div className="flex justify-center items-center w-full ">
      <div className="flex justify-center items-center Modal ">
        <Modal
          title={false}
          open={openModal}
          footer={false}
          closeIcon={false}
          width={900}
        >
          <div className="flex justify-center items-center w-full flex-col mb-[50px] ">
            <div className="flex justify-between items-center flex-center w-full bg-[#acf2f9ab]  rounded-tl-[70px] px-[30px] py-[10px]  ">
              <div className="flex justify-center items-center gap-y-[5px] gap-x-[10px] w-full tablet:flex-col  h-[90px] ">
                <div className="flex justify-center items-center text-[35px] mobile:text-[20px] tablet_md:text-[24px] font-[700] ">
                  Let's Get Some Information!
                </div>
                <div className="flex justify-center items-center w-full max-w-[200px] mt-[10px] h-[40px] rounded-full text-white bg-[#AAE127]">
                  <PhoneOutlined /> &nbsp; (573) 877-7377{" "}
                </div>
              </div>
              <div
                onClick={modalCancel}
                className="flex justify-center items-center text-white text-[20px] p-[10px] rounded-full   mb-[40px] bg-[#AAE127]  cursor-pointer "
              >
                <CloseOutlined />
              </div>
            </div>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              className="flex justify-center items-center gap-x-[30px] gap-y-[20px] my-[30px] flex-wrap w-full max-w-[800px] tablet_md:max-w-full tablet_md:px-[40px] "
            >
              <div className="flex justify-center items-center w-full max-w-[350px] tablet_md:max-w-full    ">
                <Form.Item
                  label="First Name"
                  name="first_name"
                  className="w-full font-[500]"
                  rules={[
                    { required: true, message: "Please Enter the full name" },
                  ]}
                >
                  <Input className="h-[50px]" />
                </Form.Item>
              </div>
              <div className="flex justify-center items-center w-full max-w-[350px] tablet_md:max-w-full ">
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  className="w-full font-[500]"
                  rules={[
                    { required: true, message: "Please Enter the full name" },
                  ]}
                >
                  <Input className="h-[50px]" />
                </Form.Item>
              </div>
              <div className="flex justify-center items-center w-full max-w-[350px] tablet_md:max-w-full ">
                <Form.Item
                  label="Email Adress"
                  name="email"
                  className="w-full font-[500]"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the email address",
                    },
                  ]}
                >
                  <Input type="email" className="h-[50px]" />
                </Form.Item>
              </div>
              <div className="flex justify-center items-center w-full max-w-[350px] tablet_md:max-w-full ">
                <Form.Item
                  label="Confirm Email Adress"
                  name="confirm_email"
                  className="w-full font-[500]"
                  dependencies={["email"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your email address!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("email") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new email that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input type="email" className="h-[50px]" />
                </Form.Item>
              </div>
              <div className="flex justify-center items-center w-full max-w-[350px] tablet_md:max-w-full ">
                <Form.Item
                  label="Street Address"
                  name="street_address"
                  className="w-full font-[500]"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the street addrees",
                    },
                  ]}
                >
                  <Input
                    placeholder="Street Adress"
                    className=" w-full h-[50px]"
                  />
                </Form.Item>
              </div>
              <div className="flex justify-center items-center w-full max-w-[350px] tablet_md:max-w-full ">
                <Form.Item
                  label="Zip Code"
                  name="zipcode"
                  className="w-full font-[500]"
                  rules={[
                    { required: true, message: "Please Enter the full name" },
                  ]}
                >
                  <Input placeholder="Zip Code" className="h-[50px]" />
                </Form.Item>
              </div>
              <div className="flex justify-center items-center w-full max-w-[350px] tablet_md:max-w-full ">
                <Form.Item
                  label="Phone Number"
                  name="phone_number"
                  className="w-full font-[500]"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the Phone Number ",
                    },
                  ]}
                >
                  <Input className="h-[50px]" />
                </Form.Item>
              </div>
              <div className="flex justify-center items-center  mt-[20px]   w-full max-w-[350px] tablet_md:max-w-full ">
                <Form.Item
                  label="How did you hear about us?"
                  name="information"
                  className="w-full select font-[500]"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the  input your information",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select One"
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex ",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    options={[
                      { value: "internetSearch", label: "Internet Search" },
                      { value: "Angi/HomeAdvisor", label: "Angi/HomeAdvisor" },
                      { value: "Referral", label: "Referral" },
                      { value: "Billboard", label: "Billboard" },
                      { value: "Radio/TV", label: "Radio/TV" },
                      { value: "Facebook", label: "Facebook" },
                      { value: "Instagram", label: "Instagram" },
                      { value: "YouTube", label: "YouTube" },
                      { value: "Other", label: "Other" },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="flex justify-start items-start w-full px-[30px] ">
                <Form.Item className="w-full">
                  <Button
                    htmlType="submit"
                    className="flex justify-center items-center bg-[#AAE127] border-[#AAE127] hover:!border-[#AAE127]   h-full w-full max-w-[200px] text-white  text-[20px] font-[600] hover:!text-white p-[10px] rounded-full "
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ModalForm;
