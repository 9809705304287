import React, { useEffect } from "react";
import MainLayout from "../component/shared/layout/MainLayout";
import LadyComponent from "../component/shared/LadyComponent/LadyComponent";
import CertifiedComponent from "../component/shared/Certified/CertifiedComponent";
import FreeQuoteToday from "../component/shared/FreeQuote/FreeQuoteToday";
import ServicesPageTopComponent from "../component/shared/ServicesPageTopComponent/ServicesPageTopComponent";
import BenefitBattInsulation from "../component/BenefitBattInsulation/BenefitBattInsulation";
import MadeFrom from "../component/shared/MadeFrom/MadeFrom";
import {
  headerinfo,
  madeFromServicesDetail,
  service_detail,
} from "../Services/ServicesDetail";
import { useLocation } from "react-router-dom";

function ServicesDetail() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <MainLayout>
        <ServicesPageTopComponent pageName={true} pageData={service_detail} />
        <BenefitBattInsulation />
        <MadeFrom madefrom={madeFromServicesDetail} title={headerinfo} />
        <LadyComponent />
        <CertifiedComponent />
        <FreeQuoteToday />
      </MainLayout>
    </div>
  );
}

export default ServicesDetail;
