export const service_detail = {
  title: "Facilisis amet consequat ultrices",
  url: "/Images/s-bg-1.png",
  info: "At TFG, we offer several convenient financing options for homeowners. During our free inspection, we will work with you to determine the best financing option that fits your needs.",
}

export const madeFromServicesDetail = [
  {
    id: 1,
    url: "/Images/fmade-1.png",
    title: "Fiberglass",
  },
  {
    id: 2,
    url: "/Images/fmade-2.png",
    title: "Mineral Wool",
  },
  {
    id: 3,
    url: "/Images/fmade-3.png",
    title: "Recycled Cotton",
  },
]
export const headerinfo = {
  title: "Made Form",
  info: "Choose from the following materials",
}
