export const service_Crown = {
  title: "Crawl Space Encapsulation",
  url: "/Images/s-bg-2.png",
  info: "Crawl spaces, while not a prominent feature of a home, can pose their own set of challenges and should not be overlooked. The condition of your crawl space can affect the air quality within your home.",
}
export const madeFrom = [
  {
    id: 1,
    url: "/Images/made-3.png",
    title: "Viper® Vapor Barrier",
  },
  {
    id: 2,
    url: "/Images/made-4.png",
    title: "Spray Foam",
  },
  {
    id: 3,
    url: "/Images/made-5.png",
    title: "Viper® Insul-Barrier®",
  },
]

export const servies_info = {
  title: "Crawlspace Conundrums",
  url: "/Images/sc-1.png",
  info: "When dealing with your crawlspace, what's out of sight shouldn't be out of mind. If your home rests partially or entirely on a crawlspace, its condition can significantly impact both your family's health and your home's overall comfort.Issues like standing water or high humidity can lead to mold growth on floor joists or sinking floors, and contaminated pathways from plumbing, ducts, and more can allow this air to enter your living spaces. The health of your crawlspace can directly influence the durability of your home's structure, the temperature of your floors and living areas, as well as the quality of your indoor air.If your crawlspace experiences any of these issues, consider installing a sump pump to address standing water and employing a dehumidifier along with comprehensive crawlspace encapsulation and air sealing. These measures can lead to a healthier, more comfortable, and longer-lasting home.",
}
export const second_info = {
  title: "Benefits of Crawl Space Encapsulation",
  url: "/Images/sc-2.png",
  info: "Crawlspace encapsulation is a lasting and highly effective solution that offers numerous benefits. The process includes air sealing, insulating, and securing a durable vapor barrier along the walls of your crawlspace. Any vents are sealed, and rim joists are insulated and sealed with spray foam.  This comprehensive approach keeps water out, creating a dry and clean environment that prevents mold growth and preserves the integrity of your floor joists.Unlike traditional vapor barriers that may shift, accumulate deposits, and fail to regulate humidity adequately, total encapsulation provides long-lasting results, improving indoor air quality and helping regulate  floor temperatures. If you're interested in experiencing the benefits of full crawlspace encapsulation, don't hesitate to contact us. Our experts are here to walk you through the process and enhance both the comfort and integrity of your home. ",
}

export const values = [
  {
    id: 1,
    URL: "/Images/close-circle-line.png",
    title: "Stop Mold and Water Damage",
    info: "Arresting mold development can prevent damage to floor joists and improve air quality.",
  },
  {
    id: 2,
    URL: "/Images/history-line.png",
    title: "Long-Lasting Benefits",
    info: "Encapsulation is a long-term, comprehensive solution you won’t need to keep replacing.",
  },
  {
    id: 3,
    URL: "/Images/windy-line.png",
    title: "Increase Indoor Air Quality",
    info: "50% of indoor air comes from the crawlspace.  Encapsulation reduces soil gas exposure.",
  },
  {
    id: 4,
    URL: "/Images/heart-3-line.png",
    title: "Whole Home Comfort",
    info: " Insulating walls and rim joists contributes to a more comfortable home.",
  },
  {
    id: 5,
    URL: "/Images/icon1.png",
    title: "Save on Energy Bills",
    info: "Adding insulation reduces strain on HVAC and can lower your energy bills.",
  },
  {
    id: 6,
    URL: "/Images/home-2-line.png",
    title: "Increase Home Value",
    info: "Preventing water damage, mold, and structural damage will help maintain home value..",
  },
]

export const title = "Benefits of Insulation Removal"

export const headerinfoCrown = {
  title: " Products",
  info: "We use only the highest quality products",
}
