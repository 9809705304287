import { Image } from "antd"
import React from "react"

function SharedOurValueComponent({ values }) {
  return (
    <div className="flex justify-center items-center my-[40px]">
      <div className="flex justify-center items-start mobile:items-center  gap-x-[30px] gap-y-[30px] flex-wrap  mb-[40px]">
        {values?.map((item) => {
          return (
            <div
              key={item.id}
              className="flex justify-center items-start flex-col bg-[#1869A9] rounded-[20px] px-[30px] w-full max-w-[350px] h-[350px] tablet:justify-center tablet:items-center tablet:text-center  gap-y-[30px] "
            >
              <div className="flex justify-start items-start w-[77px]">
                <Image
                  src={item.URL}
                  alt="icon"
                  preview={false}
                  className="object-cover"
                />
              </div>
              <div className="flex justify-start items-start flex-col gap-y-[10px] mobile:text-center w-full ">
                <div className="flex justify-start items-start mobile:items-center mobile:justify-center text-start mobile:text-center text-[20px] font-[500] text-white leading-normal w-full ">
                  {" "}
                  {item.title}
                </div>
                <div className="flex justify-center items-center text-start tablet:text-center text-white font-[300] text-[18px] w-full max-w-[300px] ">
                  {item.info}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SharedOurValueComponent
