import { Image } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const flexCenter = "flex  items-center";

const QuickLinks = [
  {
    id: 1,
    title: "About Us",
    href: "/about",
  },
  {
    id: 2,
    title: "Contact Us",
    href: "/contact",
  },
  {
    id: 3,
    title: "Services",
    href: "/services",
  },
  {
    id: 4,
    title: "Free Resources",
    href: "/free-resoures",
  },
];

const Legalstuff = [
  {
    id: 1,
    title: "Disclaimer",
    href: "",
  },
  {
    id: 2,
    title: "Privacy Policy",
    href: "",
  },
  {
    id: 3,
    title: "Terms of Services",
    href: "",
  },
];

const mediaImages = [
  {
    id: 1,
    url: "/Images/Linkedin.png",
    href: "",
  },
  {
    id: 2,
    url: "/Images/Instagram.png",
    href: "",
  },
  {
    id: 1,
    url: "/Images/Twitter.png",
    href: "",
  },
  {
    id: 1,
    url: "/Images/Facebook.png",
    href: "",
  },
];

function Footer() {
  const navigate = useNavigate();

  const pageNavigation = (link) => {
    navigate(`${link}`);
  };
  return (
    <div
      className={`${flexCenter} justify-evenly tablet_LG:flex-col-reverse tablet_LG:justify-start px-[20px] bg-[#1869A9]   `}
    >
      <div
        className={`${flexCenter} justify-center tablet_LG:justify-start mobile:justify-center w-full max-w-[400px] py-[50px]`}
      >
        <div className={`${flexCenter} justify-center w-[120px] h-[72px] img`}>
          <Image
            src="/Images/logo-footer.png"
            alt=""
            className="object-cover"
          />
        </div>
        <div className={`${flexCenter} justify-start flex-col mb-[20px] `}>
          <div
            className={`${flexCenter} text-[28px] font-[700] leading-normal text-white `}
          >
            SAFEGUARD
          </div>
          <div
            className={`flex items-start justify-start text-white text-[11px] font-[700] `}
          >
            {" "}
            HOME SOLUTIONS
          </div>
        </div>
      </div>

      <div
        className={`flex items-start  justify-evenly flex-wrap mobile:justify-between  w-full mobile:gap-y-[30px]  py-[50px] `}
      >
        <div className={`flex items-start flex-col justify-start `}>
          <div
            className={`${flexCenter} justify-center  text-[18px] text-white font-[700]  leading-[28px] mb-[20px] `}
          >
            Quick Link
          </div>
          {QuickLinks.map((link) => {
            return (
              <div
                onClick={() => pageNavigation(link.href)}
                key={link.id}
                className={`flex items-start justify-center flex-col text-white text-[16px]
            font-[400px] leading-normal cursor-pointer `}
              >
                {link.title}
              </div>
            );
          })}
        </div>
        <div className={`flex items-start flex-col justify-start `}>
          <div
            className={`${flexCenter} justify-center  text-[18px] text-white font-[700]  leading-[28px] mb-[20px] `}
          >
            Legal Stuff
          </div>
          {Legalstuff?.map((link) => {
            return (
              <div
                key={link.id}
                className={`flex items-start justify-center flex-col text-white text-[16px]
            font-[400px] leading-normal  `}
              >
                {link.title}
              </div>
            );
          })}
        </div>
        <div
          className={`flex items-start flex-col justify-start  mobile:justify-start`}
        >
          <div
            className={`${flexCenter} justify-center  text-[18px] text-white font-[700]  leading-[28px]`}
          >
            Find us on social media
          </div>
          <div className="flex items-center justify-between w-full mt-[10px] cursor-pointer ">
            {mediaImages?.map((link) => {
              return (
                <div
                  key={link.id}
                  className={`flex items-center justify-center  `}
                >
                  <Image
                    src={link.url}
                    alt="media-icon"
                    preview={false}
                    className="object-cover"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
