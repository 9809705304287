import React, { useEffect } from "react";
import MainLayout from "../component/shared/layout/MainLayout";
import FreeQuoteToday from "../component/shared/FreeQuote/FreeQuoteToday";
import CertifiedComponent from "../component/shared/Certified/CertifiedComponent";
import LadyComponent from "../component/shared/LadyComponent/LadyComponent";
import ContactComponent from "../component/ContactComponent/ContactComponent";
import ContactForm from "../component/ContactForm/ContactForm";
import { useLocation } from "react-router-dom";

function Contact() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <MainLayout>
        <ContactComponent />
        <ContactForm />
        <LadyComponent />
        <CertifiedComponent />
        <FreeQuoteToday />
      </MainLayout>
    </div>
  );
}

export default Contact;
