import React, { useEffect } from "react";
import MainLayout from "../component/shared/layout/MainLayout";
import YourInsulation from "../component/yourInsulation/YourInsulation";
import PercentageABS from "../component/PercentageABS/PercentageABS";
import HappyCustomer from "../component/HappyCustomer/HappyCustomer";
import OurValues from "../component/OurValues/OurValues";
import SafeBet from "../component/SafeBet/SafeBet";
import AdvantageSafeGuard from "../component/AdvantageSafeGuard/AdvantageSafeGuard";
import MapComponent from "../component/MapComponent/MapComponent";
import BetterInSulation from "../component/BetterInsulation/BetterInSulation";
import LadyComponent from "../component/shared/LadyComponent/LadyComponent";
import CertifiedComponent from "../component/shared/Certified/CertifiedComponent";
import FreeQuoteToday from "../component/shared/FreeQuote/FreeQuoteToday";
import HouseHomeComponent from "../component/shared/HomeHouseComponent.jsx/HomeHouseComponent";
import { useLocation } from "react-router-dom";
import OurServices from "../component/OurServices/OurServices";

const homeHouse = {
  title: "Hear From A Happy Customer in Mid-Missouri",
  info: "Hire them, we will again! ! - Mary W. Lake Ozark, M",
};

function HomePage() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <MainLayout>
      <YourInsulation />
      <PercentageABS />
      <HappyCustomer />
      <OurValues />
      <SafeBet />
      <AdvantageSafeGuard />
      <MapComponent />
      <HouseHomeComponent houseData={homeHouse} />
      <OurServices />
      <BetterInSulation />
      <LadyComponent />
      <CertifiedComponent />
      <FreeQuoteToday />
    </MainLayout>
  );
}

export default HomePage;
